/**
 * ランダムID作成用メソッド
 * @returns 15桁:string
 */
export function randomId(): string {
  const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const N = 15;
  const randamId = Array.from(Array(N))
    .map(() => S[Math.floor(Math.random() * S.length)])
    .join("");
  return randamId;
}
