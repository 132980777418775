import { AddCircleOutline } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";

import { MESSAGES } from "../../message";
import { Classroom } from "../../types";
import { AddClassroomDialog } from "../dialogs/AddClassroomDialog";

type AddClassroomButtonProps = {
  currentClassroom: Classroom | undefined;
  onAddNewClassroom: (newClassroom: Classroom) => void;
};

export function AddClassroomButton(props: AddClassroomButtonProps) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <Tooltip title={MESSAGES.TOOLTIPS.ADD_CLASSROOM} arrow>
        <IconButton size="small" onClick={handleClick}>
          <AddCircleOutline />
        </IconButton>
      </Tooltip>

      <AddClassroomDialog
        currentClassroom={props.currentClassroom}
        open={open}
        onClickOk={props.onAddNewClassroom}
        onClose={handleClose}
      />
    </>
  );
}
