import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

import { MESSAGES } from "../../message";
import { randomId } from "../../scripts/randomId";
import { Classroom, EvaluationItem, Learner } from "../../types";
import { CancelButton } from "../buttons/CancelButton";
import { OkButton } from "../buttons/OkButton";
import {
  integerValidation,
  notEmptyValidation,
  ValidationTextField,
} from "../inputs/ValidationTextField";

type AddLearnerDialogProps = {
  open: boolean;
  currentClassroom: Classroom;
  onClose: () => void;
  onClickOk: (newLearner: Learner) => void;
};

/**
 * 学習者追加用ダイアログ
 * フォーム内のStringとNumberの値を返す。
 * @param props
 * @returns
 */
export function AddLearnerDialog(props: AddLearnerDialogProps): JSX.Element {
  //新規学習者入力管理用state
  const [newLearnerName, setNewLearnerName] = React.useState("");
  const [newLearnerNumber, setNewLearnerNumber] = React.useState<string>("");
  const [validationMode, setValidationMode] = React.useState<boolean>(false);

  // バリデーション
  const naturalNumberValidation = {
    isValid: (inputValue?: string) => {
      return integerValidation.isValid(inputValue) && Number(inputValue) > 0;
    },
    errorMessage: MESSAGES.ERROR.INPUT.INVALID_NATURAL_NUMBER,
  };
  const noDupLearnerValidation = {
    isValid: () => {
      const dupLearners = props.currentClassroom.learners.filter(
        (learner) =>
          learner.name === newLearnerName &&
          learner.number === Number(newLearnerNumber)
      );
      return dupLearners.length === 0;
    },
    errorMessage: MESSAGES.ERROR.INPUT.INVALID_DUP_LEARNER,
  };

  //リセット処理
  const resetNewLearnerState = () => {
    setValidationMode(false);
    setNewLearnerName("");
    setNewLearnerNumber("");
  };

  const handleOkClick = () => {
    setValidationMode(true);
    // 入力値の確認
    if (
      notEmptyValidation.isValid(newLearnerName) &&
      naturalNumberValidation.isValid(newLearnerNumber) &&
      noDupLearnerValidation.isValid()
    ) {
      //入力フォームを元に新規作成した学習者データ
      const newLearner: Learner = {
        id: randomId(),
        name: newLearnerName,
        number: Number(newLearnerNumber),
        evaluationItems: convertToLearnerEvaluationItems(
          props.currentClassroom.evaluationItems
        ),
        coord: { col: 0, row: 0 },
      };
      props.onClickOk(newLearner);
      resetNewLearnerState();
      props.onClose();
    }
  };

  const handleCancelClick = () => {
    resetNewLearnerState();
    props.onClose();
  };

  return (
    <Dialog
      sx={{ flexDirection: "column" }}
      onClose={handleCancelClick}
      open={props.open}
    >
      <DialogTitle>{"学習者追加"}</DialogTitle>
      <DialogContent sx={{ width: "32em", height: "12em" }}>
        <ValidationTextField
          label={"学習者氏名"}
          value={newLearnerName}
          type="text"
          validationMode={validationMode}
          validations={[notEmptyValidation, noDupLearnerValidation]}
          maxLength={20}
          onChange={(attr) => setNewLearnerName(attr.value)}
        />
        <ValidationTextField
          label={"学習者番号"}
          value={newLearnerNumber}
          type="number"
          defaultHelperText={MESSAGES.ERROR.INPUT.INVALID_NATURAL_NUMBER}
          validationMode={validationMode}
          validations={[naturalNumberValidation, noDupLearnerValidation]}
          maxLength={4}
          onChange={(attr) => setNewLearnerNumber(attr.value)}
        />
      </DialogContent>
      <DialogActions>
        <OkButton onClick={handleOkClick} />
        <CancelButton onClick={handleCancelClick} />
      </DialogActions>
    </Dialog>
  );
}

/**
 * evaluationItemsを元にLearnerEvaluationItemValueを作成するメソッド
 * @param evaluationItems
 * @returns
 */
function convertToLearnerEvaluationItems(
  evaluationItems: Array<EvaluationItem>
) {
  const newLearnerEvaluationItemValue: Array<
    [string, number | string | boolean]
  > = evaluationItems.map((evaluationItem) => [
    evaluationItem.id,
    evaluationItem.default,
  ]);

  return Object.fromEntries(newLearnerEvaluationItemValue);
}
