import { Button } from "@mui/material";
import React from "react";

type MenuButtonProps = {
  id: string;
  label: string;
  color:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  autoFocus?: boolean;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};
export function MenuButton(props: MenuButtonProps): JSX.Element {
  return (
    <Button
      id={props.id}
      autoFocus={props.autoFocus ?? false}
      color={props.color}
      variant="contained"
      onClick={props.onClick}
      disabled={props.disabled ?? false}
      sx={{ textTransform: "none" }}
    >
      {props.label}
    </Button>
  );
}
