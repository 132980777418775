import axios, { AxiosError } from "axios";
axios.defaults.timeout = 5000;

const httpErrorHandler = (err: AxiosError<unknown>) => {
  // TODO: httpStatusで200とか403とかのコードを取得することができるので需要があれば後で細かいエラーハンドリングを行う。
  //   const httpStatus = err.response?.status;
  throw err;
};

export const post = async <T extends object>(
  url: string,
  params: Record<string, string> = {}
): Promise<T> => {
  const ret = await axios.post<T>(url, params).catch(httpErrorHandler);
  return ret.data;
};
