export const MESSAGES = {
  COMMON: {
    PRODUCT_NAME: "らくらく座席表",
  },
  PAGES: {
    LOGIN: {
      SIGNIN_WITH_PROVIDER: "お手持ちのアカウントでログイン",
      OR: "または",
      SIGNIN_WITH_EMAIL: "メールアドレスでログイン",
    },
    SIGNUP: {
      EMAIL_PASSWORD_REGISTRATION: "メールアドレス／パスワード登録",
      VERIFY_YOUR_EMAIL:
        "登録ボタンを押下すると設定したメールアドレスに確認メールが送信されますのでメール内のリンクから確認作業を行ってください。",
    },
  },
  INFO: {
    AUTH: {
      LOGIN_SUCCESS: "ログインしました。",
      VERIFY_EMAIL_SENT:
        "設定されたメールアドレスに確認用メールを送信しました。メール内のリンクから確認作業を行ってください。",
    },
    SAVE: {
      TRY_SAVE_CHANGES: "変更した内容を保存しますか？",
      SUCCESS: "データの保存に成功しました。",
    },
    DELETE: {
      CONFIRM_DELETE_EVALUATION_ITEM: "評価項目を削除してよろしいですか？",
      CONFIRM_DELETE_CLASSROOM: "授業を削除してよろしいですか？",
    },
    IMPORT: {
      LEARNERS_CSV_DROPZONE:
        "ここにインポート用の生徒情報ファイルをドラッグ&ドロップするか、クリックしてファイルを選択してください。",
      CONFIRM_IMPORT_LEARNERS_CSV:
        "ファイルのインポートを開始しますか？現在表示されている生徒情報はインポートファイルの内容で上書きされます。",
      FILE_DROP: "ここにファイルをドロップしてください。",
      SUCCESS: "正常にインポートが完了しました。",
    },
  },
  ERROR: {
    AUTH: {
      LOGIN_FAILURE: "ログインに失敗しました。もう一度ログインをしてください。",
      LOGIN_VERIFY_YOUR_EMAIL:
        "ログインするにはメールアドレスの確認作業を完了する必要があります。登録時に送信されたメールから確認作業を完了してください。",
      LOGIN_INVALID_USER:
        "有効なユーザーではないためログイン出来ませんでした。",
      SIGNUP_FAILURE: "ユーザー登録に失敗しました。",
      SIGNUP_INVALID_USER:
        "有効なユーザーのメールアドレスではないためユーザー登録が出来ませんでした。",
      NO_AUTH:
        "認証情報が見つかりませんでした。もう一度ログインをしてください。",
    },
    INPUT: {
      INVALID_EMPTY_TEXT: "1文字以上入力してください。",
      INVALID_NUMBER: "数値を入力してください。",
      INVALID_INTEGER: "整数を入力してください。",
      INVALID_DATE: "日付を入力してください。",
      INVALID_NATURAL_NUMBER: "0より大きい整数を入力してください。",
      INVALID_EMPTY_DEFAULT_OPTION:
        "デフォルトの選択肢にチェックを入れてください。",
      INVALID_MIN_NUMBER: "最大値より小さい整数を入力してください。",
      INVALID_MAX_NUMBER: "最小値より大きい整数を入力してください。",
      INVALID_DEFAULT_NUMBER:
        "最小値以上、最大値以下の整数を入力してください。",
      INVALID_DUP_OPTION:
        "選択肢の表示名に重複があります。別の値を入力してください。",
      INVALID_DUP_LEARNER:
        "入力された氏名と番号の組み合わせが既に登録されています。別の値を入力してください。",
    },
    SAVE: {
      FAILURE: "データの保存に失敗しました。",
      FAILURE_INVALID_USER:
        "有効なユーザーではないためデータの保存が出来ませんでした。もう一度ログインしてください。",
    },
    DB: {
      FETCH_FAILURE:
        "データの取得に失敗しました。もう一度ログインをしてください。",
    },
    IMPORT: {
      INVALID_HEADER:
        "入力ファイルが不正な項目のヘッダーであったためインポートすることが出来ませんでした。項目の過不足や項目の並びを確認してください。",
      INVALID_EMPTY_TEXT:
        "項目の入力値が空であったためインポートすることが出来ませんでした。",
      INVALID_NATURAL_NUMBER:
        "項目の入力値が正の整数ではなかったためインポートすることが出来ませんでした。",
      INVALID_POSITIVE_INTEGER:
        "項目の入力値が0以上の整数ではなかったためインポートすることが出来ませんでした。",
      INVALID_RANGE_INTEGER:
        "項目の入力値が設定されている最小・最大範囲内の整数ではなかったためインポートすることが出来ませんでした。",
      INVALID_OPTION:
        "項目の入力値が登録されている選択肢に存在しない値であったためインポートすることが出来ませんでした。",
      INVALID_DUP_LEARNER:
        "学習者氏名と学習者番号の組み合わせに重複があったためインポートすることが出来ませんでした。",
    },
  },
  BUTTON: {
    LOGIN: "ログイン",
    LOGIN_WITH_GOOGLE: "Googleアカウントでログイン",
    MAIL_USER_REGISTRATION: "メールアドレスでの新規登録はこちら",
    REGISTRATION: "登録",
  },
  PLACEHOLDER: {
    EMAIL: "メールアドレス",
    PASSWORD: "パスワード",
  },
  TUTORIAL: {
    INTRODUCTION: {
      TITLE: "らくらく座席表チュートリアル",
      SUBTITLE: "ここではらくらく座席表について説明します。",
      MAIN: ["らくらく座席表を使って、", "授業の管理をしましょう！"],
    },
    STEP1: {
      TITLE: "Step 1: 授業の追加",
      MAIN: "まず初めに授業を追加してみましょう！",
    },
    STEP2: {
      TITLE: "Step 2: 授業の管理",
      MAIN: "授業の追加や切り替えが簡単に行えます！",
    },
    STEP3: {
      TITLE: "Step 3: 学習者の管理",
      MAIN: "学習者の追加や削除が簡単に行えます！",
    },
    STEP4: {
      TITLE: "Step 4: 評価の管理",
      MAIN: [
        "出席状況など、学習者への評価が追加できます。",
        "評価は学習者の座席に表示されます！",
      ],
    },
    STEP5: {
      TITLE: "Step 5: 評価の変更",
      MAIN: [
        "対象の学習者をクリックするだけで、",
        "簡単に評価を変更できます。",
      ],
    },
    STEP6: {
      TITLE: "Step 6: 授業の保存",
      MAIN: "授業の追加や評価の変更を保存できます。",
    },
    CONCLUSION: {
      TITLE: "らくらく座席表を使ってみましょう！",
      MAIN: "このチュートリアルはいつでも確認できます！",
    },
  },
  TOOLTIPS: {
    ADD_EVALUATION_ITEM: "評価項目追加",
    REMOVE_EVALUATION_ITEM: "評価項目削除",
    ADD_LEARNER: "学習者追加",
    REMOVE_LEARNER: "学習者削除",
    ADD_CLASSROOM: "授業追加",
    REMOVE_CLASSROOM: "授業削除",
    TUTORIAL: "チュートリアル",
    MENU: "メニュー",
    FILE_EXPORT: "csvファイルのエクスポート",
    FILE_IMPORT: "csvファイルのインポート",
  },
};
