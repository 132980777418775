import type { AlertProps } from "@mui/lab/Alert";
import { Alert, Snackbar, Typography } from "@mui/material";
import React, { useState } from "react";

type CustomSnackbarProps = {
  open: boolean;
  snackbarState: {
    message: string | Array<string>;
    severity: AlertProps["severity"];
    autoHideDuration: number;
    handleClose: () => void;
  };
};
/**
 * メッセージを出力するためのsnackbarを表示するコンポーネント。
 * @param {CustomSnackbarProps} props
 * @param {boolean} props.open trueの場合snackbarを表示する。
 * @param {string} props.snackbarState.message snackbarに表示するメッセージ。
 * @param {string} props.snackbarState.severity 表示するメッセージの種類。"success"、"info"、"warning"、"error"で指定する。
 * @param {number} props.snackbarState.autoHideDuration snackbarが自動で消えるまでの時間。ミリ秒で指定する。
 * @param {() => void} props.snackbarState.handleClose snackbarを非表示にした際に実行される処理。
 * @returns {JSX.Element}
 */
export function CustomSnackbar(props: CustomSnackbarProps): JSX.Element {
  const handleClose = (_?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      // snackbarの外をクリックした際に閉じられないようにする。
      return;
    }
    props.snackbarState.handleClose();
  };

  // メッセージを画面表示用の形式に加工する
  const message = Array.isArray(props.snackbarState.message)
    ? props.snackbarState.message.map((value, index) => (
        <Typography key={`snackbar-msg-${index}`}>{value}</Typography>
      ))
    : props.snackbarState.message;

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={props.snackbarState.autoHideDuration}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        severity={props.snackbarState.severity}
        onClose={handleClose}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export type CustomSnackbarState = {
  open: boolean;
  snackbarState: CustomSnackbarProps["snackbarState"];
  displaySuccessMessage: (message: string | Array<string>) => void;
  displayInfoMessage: (message: string | Array<string>) => void;
  displayWarningMessage: (message: string | Array<string>) => void;
  displayErrorMessage: (message: string | Array<string>) => void;
  initDisplayTime: () => void;
  setDisplayTime: (
    messageType: AlertProps["severity"],
    displayTime: number
  ) => void;
};
/**
 * CustomSnackbarを操作するためのオブジェクト。
 * 戻り値のopenとsnackbarStateをCustomSnackbarのpropsに渡した後、各functionを実行することでsnackbarを操作できる。
 * @param {string} locale メッセージに表示される時分秒の表記方法を指定する。ロケール識別子。
 * @returns {CustomSnackbarState}
 */
export function useCustomSnackbarState(): CustomSnackbarState {
  const [open, setOpen] = useState<boolean>(false);
  const [snackbarState, setSnackbarState] = useState<
    CustomSnackbarProps["snackbarState"]
  >({
    message: "",
    severity: "success",
    autoHideDuration: 0,
    handleClose: () => setOpen(false),
  });
  const [autoHideDuration, setAutoHideDuration] = useState({
    success: 5000,
    info: 5000,
    warning: 5000,
    error: 5000,
  });

  /**
   * 成功メッセージを出力するsnackbar(背景緑色)を表示する。
   * @param {string | string[]} message 表示するメッセージ。
   */
  const displaySuccessMessage = (message: string | Array<string>) => {
    setOpen(true);
    setSnackbarState({
      message: message,
      severity: "success",
      autoHideDuration: autoHideDuration.success,
      handleClose: () => setOpen(false),
    });
  };

  /**
   * 通知メッセージを出力するsnackbar(背景青色)を表示する。
   * @param {string | string[]} message 表示するメッセージ。
   */
  const displayInfoMessage = (message: string | Array<string>) => {
    setOpen(true);
    setSnackbarState({
      message: message,
      severity: "info",
      autoHideDuration: autoHideDuration.info,
      handleClose: () => setOpen(false),
    });
  };

  /**
   * 警告メッセージを出力するsnackbar(背景橙色)を表示する。
   * @param {string | string[]} message 表示するメッセージ。
   */
  const displayWarningMessage = (message: string | Array<string>) => {
    setOpen(true);
    setSnackbarState({
      message: message,
      severity: "warning",
      autoHideDuration: autoHideDuration.warning,
      handleClose: () => setOpen(false),
    });
  };

  /**
   * エラーメッセージを出力するsnackbar(背景赤色)を表示する。
   * @param {string | string[]} message 表示するメッセージ。
   */
  const displayErrorMessage = (message: string | Array<string>) => {
    setOpen(true);
    setSnackbarState({
      message: message,
      severity: "error",
      autoHideDuration: autoHideDuration.error,
      handleClose: () => setOpen(false),
    });
  };

  /**
   * snackbarの表示時間を初期値(５秒)に設定する。
   */
  const initDisplayTime = () => {
    setAutoHideDuration({
      success: 5000,
      info: 5000,
      warning: 5000,
      error: 5000,
    });
  };

  /**
   * snackbarの表示時間を設定する関数
   * @param {string} messageType 表示時間を設定するメッセージの種類。"success"、"info"、"warning"、"error"で指定する。
   * @param {number} displayTime 新しく設定したい表示時間をミリ秒でしていする。
   * @returns {void}
   */
  const setDisplayTime = (
    messageType: AlertProps["severity"],
    displayTime: number
  ) => {
    if (!messageType) {
      return;
    }
    const nextState = { ...autoHideDuration };
    nextState[messageType] = displayTime;
    setAutoHideDuration(nextState);
  };

  return {
    open,
    snackbarState,
    initDisplayTime,
    setDisplayTime,
    displaySuccessMessage,
    displayInfoMessage,
    displayWarningMessage,
    displayErrorMessage,
  };
}
