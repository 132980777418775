import React, { useState } from "react";

import { BaseMenuItemAttr, Learner } from "../../types";
import { BaseCheckList } from "../lists/BaseCheckList";
import { SimpleMenuItem } from "../menu/SimpleMenuItem";
import { BaseDialog } from "./BaseDialog";

type SelectRemoveLearnerDialog = {
  learners: Array<Learner>;
  open: boolean;
  onOkClick: (selectRemoveLearnerIds: Array<string>) => void;
  onClose: () => void;
};

/**
 *Learnerの名前を持つチェックボックスリストをもち、
 *選択したLearnerのIDをリストとして返す。
 * @param props
 * @returns
 */
export function SelectRemoveLearnerDialog(props: SelectRemoveLearnerDialog) {
  // props.learnerをList用に変形
  const itemAttrs = props.learners.map((key) => ({
    id: key.id,
    label: key.name,
  }));

  const [selectRemoveLearnerIds, setSelectRemoveLearnerIds] = useState<
    Array<string>
  >([]);
  /**
   * LearnersからIDリストのidを切り取る
   * @param removeLearnerIds
   */
  const handleClickListItem = (removeLearnerIds: Array<string>) => {
    setSelectRemoveLearnerIds(removeLearnerIds);
  };

  const handleOkClick = () => {
    props.onOkClick(selectRemoveLearnerIds);
    props.onClose();
  };
  return (
    <BaseDialog
      label="学習者削除"
      open={props.open}
      contentSx={{ width: "20em" }}
      onOkClick={handleOkClick}
      onClose={props.onClose}
    >
      <BaseCheckList
        listItemAttrs={itemAttrs}
        createListItemComponent={createListItemComponent}
        onClickListItem={handleClickListItem}
      />
    </BaseDialog>
  );
}

function createListItemComponent(item: BaseMenuItemAttr) {
  return <SimpleMenuItem menuItemAttr={item} />;
}
