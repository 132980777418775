import { Box } from "@mui/material";
import React from "react";

import { CustomSnackbarState } from "../components/CustomSnackbar";
import { Classroom, Learner } from "../types";
import { AddLearnerButton } from "./buttons/AddLearnerButton";
import { ExportCSVButton } from "./buttons/ExportCSVButton";
import { ImportCSVButton } from "./buttons/ImportCSVButton";
import { SelectRemoveLearnerButton } from "./buttons/SelectRemoveLearnerButton";

type LearnerManagementProps = {
  snackbar: CustomSnackbarState;
  currentClassroom: Classroom;
  onSelectRemoveLearner: (selectRemoveLearnerIds: Array<string>) => void;
  onAddNewLearner: (newLearner: Array<Learner>) => void;
  onImportLearners: (importedLearners: Array<Learner>) => void;
};

export function LearnerManagement(props: LearnerManagementProps): JSX.Element {
  return (
    <Box>
      <SelectRemoveLearnerButton
        learners={props.currentClassroom.learners}
        onSelectRemoveLearner={props.onSelectRemoveLearner}
      />

      <AddLearnerButton
        currentClassroom={props.currentClassroom}
        onAddNewLearner={props.onAddNewLearner}
      />

      <ExportCSVButton currentClassroom={props.currentClassroom} />
      <ImportCSVButton
        snackbar={props.snackbar}
        currentClassroom={props.currentClassroom}
        onImportLearners={props.onImportLearners}
      />
    </Box>
  );
}
