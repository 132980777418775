import type { SelectChangeEvent } from "@mui/material";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

import { BaseMenuItemAttr } from "../../types";

type MenuItemComponentProps<T extends BaseMenuItemAttr> = {
  menuItemAttr: T;
};

type BaseSelectInputProps<T extends BaseMenuItemAttr> = {
  label: string;
  selectedValue: string;
  menuItemAttrs: Array<T>;
  menuItemComponent: (props: MenuItemComponentProps<T>) => JSX.Element;
  lineDivide: boolean;
  onChange: (itemId: string) => void;
};
export function BaseSelectInput<T extends BaseMenuItemAttr>(
  props: BaseSelectInputProps<T>
): JSX.Element {
  const handleChange = (e: SelectChangeEvent<string>) => {
    props.onChange(e.target.value);
  };

  return (
    <FormControl size="small" margin="normal" fullWidth>
      <InputLabel>{props.label}</InputLabel>
      <Select
        label={props.label}
        value={props.selectedValue}
        onChange={handleChange}
      >
        {props.menuItemAttrs !== undefined ? (
          props.menuItemAttrs.map((menuItemAttr, index) => {
            // 線を引くかどうかの判定を行う。
            const withLine =
              props.lineDivide && props.menuItemAttrs.length - 1 !== index;
            return withLine ? (
              [
                <MenuItem key={menuItemAttr.id} value={menuItemAttr.id}>
                  <props.menuItemComponent menuItemAttr={menuItemAttr} />
                </MenuItem>,
                <Divider key={`${menuItemAttr.id}-divider`} />,
              ]
            ) : (
              <MenuItem key={menuItemAttr.id} value={menuItemAttr.id}>
                <props.menuItemComponent menuItemAttr={menuItemAttr} />
              </MenuItem>
            );
          })
        ) : (
          <MenuItem key={"empty"}></MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
