import {
  Button,
  CssBaseline,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useNavigate } from "react-router";

import { CustomSnackbarState } from "../components/CustomSnackbar";
import { useAuth } from "../context/useAuth";
import { MESSAGES } from "../message";
import { PATH } from "../scripts/routes";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#f5f5f5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
  },
  paper: {
    width: "34em",
    height: "23.5em",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "3em",
  },
}));

type SignUpPageProps = {
  snackbar: CustomSnackbarState;
};

export function SignUpPage(props: SignUpPageProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const auth = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();

  // ログインボタンをクリックした際に実行する処理
  const handleClicEmailSignUp = async () => {
    try {
      setIsLoading(true);
      // 有効なユーザーでない場合ログイン失敗
      const isValid = await auth.getAuthorization(email);
      if (!isValid) {
        await processSignUpFailure(isValid);
      } else {
        await auth.emailSignUp(email, password);
        processSignUpSuccess();
      }
    } catch (error) {
      if (error instanceof Error) {
        await processSignUpFailure();
        throw error;
      }
    }
  };

  // 登録成功時
  const processSignUpSuccess = () => {
    props.snackbar.displayInfoMessage(MESSAGES.INFO.AUTH.VERIFY_EMAIL_SENT);
    navigate(PATH.LOGIN);
  };

  // 登録失敗時
  const processSignUpFailure = async (isValid?: boolean) => {
    if (isValid === false) {
      props.snackbar.displayWarningMessage(
        MESSAGES.ERROR.AUTH.SIGNUP_INVALID_USER
      );
    } else {
      props.snackbar.displayErrorMessage(MESSAGES.ERROR.AUTH.SIGNUP_FAILURE);
    }
    setIsLoading(false);
    await auth.logOut();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <div className={classes.content}>
          <Typography variant="h5">
            {MESSAGES.PAGES.SIGNUP.EMAIL_PASSWORD_REGISTRATION}
          </Typography>
          <br />
          <TextField
            type="text"
            variant="outlined"
            label={MESSAGES.PLACEHOLDER.EMAIL}
            size="small"
            sx={{ width: "75%", marginTop: "0.5em" }}
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
          ></TextField>
          <TextField
            type="password"
            variant="outlined"
            label={MESSAGES.PLACEHOLDER.PASSWORD}
            size="small"
            sx={{ width: "75%", marginTop: "0.5em" }}
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
          ></TextField>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClicEmailSignUp}
            disabled={isLoading}
            sx={{ width: "75%", marginTop: "0.5em" }}
          >
            {MESSAGES.BUTTON.REGISTRATION}
          </Button>
          <br />
          <Typography variant="subtitle2">
            {MESSAGES.PAGES.SIGNUP.VERIFY_YOUR_EMAIL}
          </Typography>
        </div>
      </Paper>
    </div>
  );
}
