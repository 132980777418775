import { Box, Button } from "@mui/material";
import React from "react";

type SimpleSeatButtonProps = {
  name: string;
};

export function SimpleSeatButton(props: SimpleSeatButtonProps): JSX.Element {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Button
        variant="contained"
        sx={{
          width: "100%",
          height: "100%",
          padding: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          className="seat-button-text"
          sx={{
            width: "100%",
            height: "50%",
          }}
        >
          {props.name}
        </Box>
        <Box sx={{ flexGrow: 1 }}></Box>
      </Button>
    </Box>
  );
}
