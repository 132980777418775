import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore";

import { RAKURAKU_AUTHORIZATION_URI, USERS_PATH } from "../configs/config";
import { Classroom, EvaluationItem, Learner, RakurakuUser } from "../types";
import { db } from "./fireBaseSettings";
import { post } from "./http";

// dbのuserに新規のuserデータ(*1)を追加する。
// *1: 画面上に表示するclassroom情報、評価項目情報、生徒情報などを入れる。認可情報は含まない。
export const addUser = async (
  uid: string,
  email: string | null
): Promise<RakurakuUser | undefined> => {
  if (email === null) {
    return undefined;
  }
  // 初期userデータを作成
  const insertUserData: RakurakuUser = {
    email: email,
    currentClassroomId: "",
    classrooms: [],
  };
  const userRef = doc(db, USERS_PATH, uid);
  await setDoc(userRef, insertUserData, { merge: true });
  const rakurakuUser = await readUser(uid);
  return rakurakuUser;
};

// dbに保存されている対象uidのuserデータ(*1)を読む。
// *1: 画面上に表示するclassroom情報、評価項目情報、生徒情報など。認可情報は含まない。
export const readUser = async (
  uid: string
): Promise<RakurakuUser | undefined> => {
  const userQuerySnapshot = await getDoc(doc(db, USERS_PATH, uid));
  const email: string | undefined = userQuerySnapshot.get("email");
  const currentClassroomId: string | undefined =
    userQuerySnapshot.get("currentClassroomId");
  const classrooms = userQuerySnapshot.get("classrooms");
  if (
    email !== undefined &&
    currentClassroomId !== undefined &&
    classrooms !== undefined
  ) {
    const rakurakuClassroom: Array<Classroom> = classrooms.map(
      (fClassroom: FireStoreClassroom) => {
        const newClassroom: Classroom = {
          id: fClassroom.id,
          name: fClassroom.name,
          date: fClassroom.date.toDate(),
          period: fClassroom.period,
          learners: fClassroom.learners,
          evaluationItems: fClassroom.evaluationItems,
        };
        return newClassroom;
      }
    );
    const rakurakuUser: RakurakuUser = {
      email: email,
      currentClassroomId: currentClassroomId,
      classrooms: rakurakuClassroom,
    };
    return rakurakuUser;
  }
  return undefined;
};

// dbのuser(*1)を更新する。
// *1: 画面上に表示するclassroom情報、評価項目情報、生徒情報などを入れる。認可情報は含まない。
export const updateUser = async (
  uid: string,
  rakurakuUser: RakurakuUser
): Promise<boolean> => {
  try {
    const userRef = doc(db, USERS_PATH, uid);
    await setDoc(userRef, rakurakuUser, { merge: false });
    return true;
  } catch {
    return false;
  }
};

type FireStoreClassroom = {
  id: string;
  name: string;
  date: Timestamp;
  period: number;
  learners: Array<Learner>;
  evaluationItems: Array<EvaluationItem>;
};

type RakurakuAuthorizationResult = {
  result: number;
  message: string;
  start_at?: string;
};

// ログインユーザーの認可情報を取得する。
// 課金ユーザーの場合trueが返る。
export const getRakurakuAuthorization = async (
  email: string | null
): Promise<boolean> => {
  try {
    if (email === null) return false;
    const ret = await post<RakurakuAuthorizationResult>(
      RAKURAKU_AUTHORIZATION_URI,
      { email: email }
    );
    return ret.result === 0 ? true : false;
  } catch {
    return false;
  }
};
