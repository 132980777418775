import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFirestore } from "firebase/firestore";

import { firebaseConfig } from "../configs/firebaseConfig";
import { RE_CAPTCHA_V3_SITE_KEY } from "../configs/reCaptchaV3SiteKey";

export const app = initializeApp(firebaseConfig);
export const db = getFirestore();

// app-checkの設定
// localhostのときはdebugモードをonにする。
type AppCheckDebug = Window &
  typeof globalThis & { FIREBASE_APPCHECK_DEBUG_TOKEN: boolean };
if (location.hostname === "localhost") {
  (self as AppCheckDebug).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(RE_CAPTCHA_V3_SITE_KEY),
  isTokenAutoRefreshEnabled: true,
});
