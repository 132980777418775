import type { SxProps, Theme } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { ReactNode } from "react";

import { CancelButton } from "../buttons/CancelButton";
import { OkButton } from "../buttons/OkButton";

type BaseDialogProps = {
  label: string;
  open: boolean;
  children: ReactNode;
  contentSx?: SxProps<Theme>;
  onOkClick: () => void;
  onClose: () => void;
};

export function BaseDialog(props: BaseDialogProps): JSX.Element {
  return (
    <Dialog
      sx={{ flexDirection: "column" }}
      onClose={props.onClose}
      open={props.open}
    >
      <DialogTitle>{props.label}</DialogTitle>
      <DialogContent sx={props.contentSx}>{props.children}</DialogContent>
      <DialogActions>
        <OkButton onClick={props.onOkClick} />
        <CancelButton onClick={props.onClose} />
      </DialogActions>
    </Dialog>
  );
}
