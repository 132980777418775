import { Box, Tab, Tabs } from "@mui/material";
import React from "react";

import { EvaluationItem } from "../types";
import { AddEvaluationItemButton } from "./buttons/AddEvaluationItemButton";
import { EvaluationItemButton } from "./buttons/EvaluationItemButton";

type EvaluationItemBarProps = {
  evaluationItems: Array<EvaluationItem>;
  currentEvaluationItem: EvaluationItem | undefined;
  onClick: (selectId: string) => void;
  onClickDelete: (selectId: string) => void;
  onClickAdd: (newEvaluationItem: EvaluationItem) => void;
};

export const EvaluationItemBar = (
  props: EvaluationItemBarProps
): JSX.Element => {
  const handleClick = (selectedId: string) => {
    props.onClick(selectedId);
  };

  return (
    <Box
      sx={{
        border: "2px solid",
        borderRadius: "0.4em",
        borderColor: "GRAY",
        display: "flex",
      }}
    >
      <Tabs
        value={false}
        variant="scrollable"
        component={"div"}
        scrollButtons="auto"
      >
        {props.evaluationItems.map((evaluationItem) => (
          <Tab
            component={"div"}
            key={evaluationItem.id}
            disableRipple={true}
            sx={{
              paddingLeft: "8px",
              paddingTop: "0px",
              paddingBottom: "0px",
              paddingRight: "0px",
            }}
            label={
              <EvaluationItemButton
                id={evaluationItem.id}
                label={evaluationItem.name}
                selected={props.currentEvaluationItem?.id === evaluationItem.id}
                onClick={handleClick}
                onClickDelete={props.onClickDelete}
              />
            }
          />
        ))}
      </Tabs>

      <AddEvaluationItemButton onClickOK={props.onClickAdd} />
    </Box>
  );
};
