import { Circle } from "@mui/icons-material";
import { IconButton, Paper } from "@mui/material";
import React from "react";

type ColorPickerProps = {
  onChange: (colorHex: string) => void;
};

const NEST_PRESET_COLORS = [
  ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3"],
  ["#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39"],
  ["#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"],
];
export const PRESET_COLORS = NEST_PRESET_COLORS.flatMap((colorHex) => colorHex);

export function ColorPicker(props: ColorPickerProps): JSX.Element {
  const handleChangeColor = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    props.onChange(e.currentTarget.value);
  };

  return (
    <Paper
      sx={{
        backgroundColor: "WHITE",
        padding: 1.5,
      }}
    >
      {NEST_PRESET_COLORS.map((row, index) => (
        <div key={index}>
          {row.map((presetColor) => (
            <IconButton
              key={presetColor}
              value={presetColor}
              onClick={handleChangeColor}
            >
              <Circle
                fontSize="large"
                sx={{
                  color: presetColor,
                }}
              />
            </IconButton>
          ))}
        </div>
      ))}
    </Paper>
  );
}
