import React from "react";

import { EvaluationItem, Learner } from "../../types";
import { BoolSeatButton } from "./BoolSeatButton";
import { CategorySeatButton } from "./CategorySeatButton";
import { CountSeatButton } from "./CountSeatButton";
import { SimpleSeatButton } from "./SimpleSeatButton";

type SeatButtonProps = {
  learner: Learner;
  evaluationItem?: EvaluationItem;
  isDragging: boolean;
  onClick: (updatedLearner: Learner) => void;
  onChangeDraggableState: (isDraggable: boolean) => void;
};
export function SeatButton(props: SeatButtonProps): JSX.Element {
  if (!props.evaluationItem) {
    return <SimpleSeatButton name={props.learner.name} />;
  }

  const evaluationItemType = props.evaluationItem.type;
  const evaluationItemId = props.evaluationItem.id;
  const learnerName = props.learner.name;
  const learnerEvaluationItemValue =
    props.learner.evaluationItems[evaluationItemId];

  const handleClick = (newValue: boolean | number | string) => {
    props.learner.evaluationItems[evaluationItemId] = newValue;
    props.onClick(props.learner);
  };

  switch (evaluationItemType) {
    case "BOOL":
      return typeof learnerEvaluationItemValue === "boolean" ? (
        <BoolSeatButton
          name={learnerName}
          value={learnerEvaluationItemValue}
          style={props.evaluationItem.style}
          isDragging={props.isDragging}
          onClick={handleClick}
        />
      ) : (
        <SimpleSeatButton name={props.learner.name} />
      );
    case "COUNT":
      return typeof learnerEvaluationItemValue === "number" ? (
        <CountSeatButton
          name={learnerName}
          value={learnerEvaluationItemValue}
          range={props.evaluationItem.range}
          isDragging={props.isDragging}
          onClick={handleClick}
        />
      ) : (
        <SimpleSeatButton name={props.learner.name} />
      );
    case "CATEGORY":
      return typeof learnerEvaluationItemValue === "string" ? (
        <CategorySeatButton
          name={learnerName}
          value={learnerEvaluationItemValue}
          style={props.evaluationItem.style}
          isDragging={props.isDragging}
          onClickCategory={handleClick}
          onChangeDraggableState={props.onChangeDraggableState}
        />
      ) : (
        <SimpleSeatButton name={props.learner.name} />
      );
  }
}
