import { Button, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { ClassroomInfo } from "../components/ClassroomInfo";
import { CustomSnackbarState } from "../components/CustomSnackbar";
import { AddClassroomDialog } from "../components/dialogs/AddClassroomDialog";
import { EvaluationItemBar } from "../components/EvaluationItemBar";
import { Header } from "../components/Header";
import { LearnerManagement } from "../components/LearnerManagement";
import { SeatButtonLayout } from "../components/SeatButtonLayout";
import { useAuth } from "../context/useAuth";
import { MESSAGES } from "../message";
import { randomId } from "../scripts/randomId";
import {
  Classroom,
  EvaluationItem,
  Learner,
  LearnerEvaluationItemValue,
  RakurakuUser,
} from "../types";

type MainPageProps = {
  snackbar: CustomSnackbarState;
};

export function MainPage(props: MainPageProps): JSX.Element {
  const auth = useAuth();
  const [rakurakuUser, setRakurakuUser] = useState<RakurakuUser | undefined>(
    auth.rakurakuUser
  );
  const email = rakurakuUser ? rakurakuUser.email : "";
  const currentClassroomId = rakurakuUser
    ? rakurakuUser.currentClassroomId
    : "";

  // stateの設定
  const [addClassroomDialogOpen, setAddClassroomDialogOpen] =
    useState<boolean>(false);
  const [classrooms, setClassrooms] = useState<Array<Classroom>>(
    rakurakuUser && rakurakuUser.classrooms.length > 0
      ? rakurakuUser.classrooms
      : []
  );
  const currentClassroomTemp = classrooms.find(
    (classroom) => classroom.id === currentClassroomId
  );
  const [currentClassroom, setCurrentClassroom] = useState<
    Classroom | undefined
  >(currentClassroomTemp ? currentClassroomTemp : classrooms[0]);
  const [evaluationItems, setEvaluationItems] = useState<Array<EvaluationItem>>(
    currentClassroom ? currentClassroom.evaluationItems : []
  );
  const [currentEvaluationItem, setCurrentEvaluationItem] = useState<
    EvaluationItem | undefined
  >(evaluationItems[0]);
  const [learners, setLearners] = useState<Array<Learner>>(
    currentClassroom ? currentClassroom.learners : []
  );

  // 初回レンダー判定用
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      const newRakurakuUser = {
        email: email,
        currentClassroomId: currentClassroom ? currentClassroom.id : "",
        classrooms: classrooms,
      };
      setRakurakuUser(newRakurakuUser);
    }
  }, [email, classrooms, currentClassroom]);

  useEffect(() => {
    // DBの情報が取得できていない場合の処理
    if (!rakurakuUser) {
      props.snackbar.displayErrorMessage(MESSAGES.ERROR.DB.FETCH_FAILURE);
      auth.logOut();
    }
  }, [rakurakuUser, auth, props.snackbar]);

  // learnersに新規(または更新)データを入れる。
  const addOrUpdateLearners = (inputNewLearners: Array<Learner>) => {
    if (!currentClassroom) return;
    const inputNewLearnersIds = inputNewLearners.map((learner) => learner.id);
    const learnersWithoutNewLearners = learners.filter(
      (learner) => !inputNewLearnersIds.includes(learner.id)
    );
    const newLearners = [...learnersWithoutNewLearners, ...inputNewLearners];
    setLearners(newLearners);
    updateCurrentClassroom(currentClassroom, newLearners, evaluationItems);
  };

  // learnersから対象idのデータを削除する。
  const removeLearners = (targetLearnerIds: Array<string>) => {
    if (!currentClassroom) return;
    const newLearners = learners.filter(
      (learner) => !targetLearnerIds.includes(learner.id)
    );
    setLearners(newLearners);
    updateCurrentClassroom(currentClassroom, newLearners, evaluationItems);
  };

  // 現在選択されているevaluationItemに設定する。
  const settingCurrentEvaluationItem = (targetEvaluationItemId: string) => {
    const targetEvaluationItem = evaluationItems.find(
      (evaluationItem) => evaluationItem.id === targetEvaluationItemId
    );
    setCurrentEvaluationItem(targetEvaluationItem);
  };
  // evaluationItemsに新規(または更新)データを入れる。
  const addOrUpdateEvaluationItem = (newEvaluationItem: EvaluationItem) => {
    if (!currentClassroom) return;
    const evaluationItemsWithoutNewEvaluationItem = evaluationItems.filter(
      (evaluationItem) => evaluationItem.id !== newEvaluationItem.id
    );
    const newEvaluationItems = [
      ...evaluationItemsWithoutNewEvaluationItem,
      { ...newEvaluationItem },
    ];
    setEvaluationItems(newEvaluationItems);
    const newLearners = learners.map((learner) => {
      return {
        ...learner,
        evaluationItems: {
          ...learner.evaluationItems,
          [newEvaluationItem.id]: newEvaluationItem.default,
        },
      };
    });
    setLearners(newLearners);
    updateCurrentClassroom(currentClassroom, newLearners, newEvaluationItems);
  };
  // evaluationItemsから対象idのデータを削除する。
  const removeEvaluationItem = (targetEvaluationItemId: string) => {
    if (!currentClassroom) return;
    const newEvaluationItems = evaluationItems.filter(
      (evaluationItem) => evaluationItem.id !== targetEvaluationItemId
    );
    setEvaluationItems(newEvaluationItems);
    const newLearners = [...learners];
    newLearners.forEach((learner) => {
      delete learner.evaluationItems[targetEvaluationItemId];
    });
    setLearners(newLearners);
    updateCurrentClassroom(currentClassroom, newLearners, newEvaluationItems);
  };

  // 現在選択されているclassroomに設定する。
  const settingCurrentClassroom = (
    newCurrentClassroom: Classroom | undefined
  ) => {
    setCurrentClassroom(newCurrentClassroom);
    setLearners(newCurrentClassroom ? newCurrentClassroom.learners : []);
    setEvaluationItems(
      newCurrentClassroom ? newCurrentClassroom.evaluationItems : []
    );
    setCurrentEvaluationItem(newCurrentClassroom?.evaluationItems[0]);
  };

  // 入力classroomを初期化してclassroomsに加える。現在のclassroomに設定もする。
  const addNewClassroom = (newClassroom: Classroom) => {
    settingInitEvaluationItems(newClassroom);
    settingCurrentClassroom(newClassroom);
    addOrUpdateClassroom(newClassroom);
  };

  // classroomsに新規(または更新)データを入れる。
  const addOrUpdateClassroom = (newClassroom: Classroom) => {
    const classroomsWithoutNewClassroom = classrooms.filter(
      (classroom) => classroom.id !== newClassroom.id
    );
    setClassrooms([...classroomsWithoutNewClassroom, { ...newClassroom }]);
  };

  // classroomに初期の評価項目を設定する。
  const settingInitEvaluationItems = (newClassroom: Classroom) => {
    const newEvaluationItems = createNewEvaluationItems();
    newClassroom.evaluationItems = newEvaluationItems;
    const evaluationItemKVArray: Array<[string, number | string | boolean]> =
      newEvaluationItems.map((evaluationItem) => [
        evaluationItem.id,
        evaluationItem.default,
      ]);
    const newLearnerEvaluationItemsValue: LearnerEvaluationItemValue<
      number | string | boolean
    > = Object.fromEntries(evaluationItemKVArray);
    newClassroom.learners.forEach((learner) => {
      learner.evaluationItems = { ...newLearnerEvaluationItemsValue };
    });
  };

  // 現在選択中のclassroomを更新する。
  const updateCurrentClassroom = (
    inputCurrentClassroom: Classroom,
    inputLearners: Array<Learner>,
    inputEvaluationItems: Array<EvaluationItem>
  ) => {
    const newCurrentClassroom = { ...inputCurrentClassroom };
    newCurrentClassroom.learners = inputLearners;
    newCurrentClassroom.evaluationItems = inputEvaluationItems;
    setCurrentClassroom(newCurrentClassroom);
    addOrUpdateClassroom(newCurrentClassroom);
  };

  // classroomsから対象idのデータを削除する。
  const removeClassroom = (targetClassroomId: string) => {
    const newClassrooms = classrooms.filter(
      (classroom) => classroom.id !== targetClassroomId
    );
    setClassrooms(newClassrooms);
    settingCurrentClassroom(newClassrooms[0]);
  };
  return (
    <main>
      {/* ヘッダー */}
      {rakurakuUser ? (
        <Header rakurakuUser={rakurakuUser} snackbar={props.snackbar} />
      ) : (
        <></>
      )}

      {currentClassroom ? (
        <>
          {/* 一行目 */}
          <Stack direction="row">
            <div
              style={{
                marginLeft: "40px",
              }}
            >
              <ClassroomInfo
                currentClassroom={currentClassroom}
                classrooms={classrooms}
                onChange={(newCurrentClassroomId) => {
                  const targetClassroom = classrooms.find(
                    (classroom) => classroom.id === newCurrentClassroomId
                  );
                  settingCurrentClassroom(targetClassroom);
                }}
                onAdd={addNewClassroom}
                onRemove={removeClassroom}
              />
            </div>
            <div style={{ flexGrow: 1 }}></div>
            <div
              style={{
                marginRight: "2.2em",
                marginTop: "3em",
              }}
            >
              <LearnerManagement
                snackbar={props.snackbar}
                currentClassroom={currentClassroom}
                onSelectRemoveLearner={removeLearners}
                onAddNewLearner={addOrUpdateLearners}
                onImportLearners={(importedLearners) => {
                  setLearners(importedLearners);
                  updateCurrentClassroom(
                    currentClassroom,
                    importedLearners,
                    evaluationItems
                  );
                }}
              />
            </div>
          </Stack>

          {/* 二行目 */}
          <EvaluationItemBar
            evaluationItems={evaluationItems}
            currentEvaluationItem={currentEvaluationItem}
            onClick={settingCurrentEvaluationItem}
            onClickDelete={removeEvaluationItem}
            onClickAdd={addOrUpdateEvaluationItem}
          />

          {/* 三行目 */}
          <div
            style={{
              marginTop: "0.3em",
            }}
          ></div>
          <SeatButtonLayout
            learners={learners}
            evaluationItem={currentEvaluationItem}
            onChangeLearners={addOrUpdateLearners}
          />
        </>
      ) : (
        <Button
          sx={{ marginLeft: "40px", marginTop: "10px", paddingLeft: 0 }}
          variant="text"
          onClick={() => setAddClassroomDialogOpen(true)}
        >
          {"最初の授業を追加"}
        </Button>
      )}
      <AddClassroomDialog
        currentClassroom={currentClassroom}
        open={addClassroomDialogOpen}
        onClickOk={addNewClassroom}
        onClose={() => setAddClassroomDialogOpen(false)}
      />
    </main>
  );
}

const createNewEvaluationItems = (): Array<EvaluationItem> => [
  {
    id: randomId(),
    name: "出席",
    type: "BOOL",
    style: {
      true: { label: "出席" },
      false: { label: "欠席" },
    },
    default: true,
  },
  {
    id: randomId(),
    name: "発言ポイント",
    type: "COUNT",
    range: {
      min: 1,
      max: 5,
    },
    default: 1,
  },
];
