import { Google } from "@mui/icons-material";
import {
  Button,
  CssBaseline,
  Divider,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useNavigate } from "react-router";

import { CustomSnackbarState } from "../components/CustomSnackbar";
import { useAuth } from "../context/useAuth";
import { MESSAGES } from "../message";
import { PATH } from "../scripts/routes";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#f5f5f5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
  },
  paper: {
    width: "34em",
    height: "36em",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "3em",
  },
}));

type LogInPageProps = {
  snackbar: CustomSnackbarState;
};

export function LogInPage(props: LogInPageProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const auth = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();

  // Googleログインボタンをクリックした際に実行する処理
  const handleClickGoogleLogin = async () => {
    try {
      setIsLoading(true);
      const user = await auth.googleLogIn();
      if (user) {
        // 有効なユーザーでない場合ログイン失敗
        const isValid = await auth.getAuthorization(user.email);
        if (!isValid) {
          await processLoginFailure(isValid);
        } else {
          // メール確認済みでない場合ログイン失敗
          user.emailVerified
            ? processLoginSuccess()
            : await processLoginFailure(isValid, user.emailVerified);
        }
      } else {
        // userが取れていない場合ログイン失敗
        await processLoginFailure();
      }
    } catch (error) {
      if (error instanceof Error) {
        await processLoginFailure();
        throw error;
      }
    }
  };

  // emailログインボタンをクリックした際に実行する処理
  const handleClickEmailLogin = async () => {
    try {
      setIsLoading(true);
      const user = await auth.emailLogIn(email, password);
      if (user) {
        // 有効なユーザーでない場合ログイン失敗
        const isValid = await auth.getAuthorization(user.email);
        if (!isValid) {
          await processLoginFailure(isValid);
        } else {
          // メール確認済みでない場合ログイン失敗
          user.emailVerified
            ? processLoginSuccess()
            : await processLoginFailure(isValid, user.emailVerified);
        }
      } else {
        // userが取れていない場合ログイン失敗
        await processLoginFailure();
      }
    } catch (error) {
      if (error instanceof Error) {
        await processLoginFailure();
        throw error;
      }
    }
  };

  // 新規ユーザー登録をクリックした際に実行する処理
  const handleClickToSignUp = () => {
    navigate(PATH.SIGNUP);
  };

  // ログイン成功時
  const processLoginSuccess = () => {
    props.snackbar.displaySuccessMessage(MESSAGES.INFO.AUTH.LOGIN_SUCCESS);
    navigate(PATH.ROOT);
  };

  // ログイン失敗時
  const processLoginFailure = async (
    isValid?: boolean,
    emailVerified?: boolean
  ) => {
    if (isValid === false) {
      props.snackbar.displayWarningMessage(
        MESSAGES.ERROR.AUTH.LOGIN_INVALID_USER
      );
    } else if (emailVerified === false) {
      props.snackbar.displayWarningMessage(
        MESSAGES.ERROR.AUTH.LOGIN_VERIFY_YOUR_EMAIL
      );
    } else {
      props.snackbar.displayErrorMessage(MESSAGES.ERROR.AUTH.LOGIN_FAILURE);
    }
    setIsLoading(false);
    await auth.logOut();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <div className={classes.content}>
          <Typography variant="h3">{MESSAGES.COMMON.PRODUCT_NAME}</Typography>
          <br />
          <Typography variant="subtitle1">
            {MESSAGES.PAGES.LOGIN.SIGNIN_WITH_PROVIDER}
          </Typography>
          <Button
            variant="contained"
            color="error"
            sx={{ width: "75%", marginTop: "0.5em", textTransform: "none" }}
            startIcon={<Google />}
            onClick={handleClickGoogleLogin}
            disabled={isLoading}
          >
            {MESSAGES.BUTTON.LOGIN_WITH_GOOGLE}
          </Button>
        </div>
        <Divider> {MESSAGES.PAGES.LOGIN.OR} </Divider>
        <div className={classes.content} style={{ padding: "1em" }}>
          <Typography variant="subtitle1">
            {MESSAGES.PAGES.LOGIN.SIGNIN_WITH_EMAIL}
          </Typography>
          <TextField
            type="text"
            variant="outlined"
            label={MESSAGES.PLACEHOLDER.EMAIL}
            size="small"
            sx={{ width: "75%", marginTop: "0.5em" }}
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
          ></TextField>
          <TextField
            type="password"
            variant="outlined"
            label={MESSAGES.PLACEHOLDER.PASSWORD}
            size="small"
            sx={{ width: "75%", marginTop: "0.5em" }}
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
          ></TextField>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickEmailLogin}
            disabled={isLoading}
            sx={{ width: "75%", marginTop: "0.5em" }}
          >
            {MESSAGES.BUTTON.LOGIN}
          </Button>
          <br />
          <Button onClick={handleClickToSignUp} disabled={isLoading}>
            {MESSAGES.BUTTON.MAIL_USER_REGISTRATION}
          </Button>
        </div>
      </Paper>
    </div>
  );
}
