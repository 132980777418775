import "./components/style.css";

import { ThemeProvider } from "@mui/system";
import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import {
  CustomSnackbar,
  useCustomSnackbarState,
} from "./components/CustomSnackbar";
import { MUI_THEME } from "./configs/rakurakuTheme";
import { ProvideAuth, useAuth } from "./context/useAuth";
import { LogInPage } from "./pages/LogInPage";
import { MainPage } from "./pages/MainPage";
import { SignUpPage } from "./pages/SignUpPage";
import { PATH } from "./scripts/routes";

/**
 * Auth認証が未完了時のリダイレクトは下記リンク先を参考
 * https://reactrouter.com/docs/en/v6/examples/auth
 */

export function App(): JSX.Element {
  const snackbar = useCustomSnackbarState();

  return (
    <ThemeProvider theme={MUI_THEME}>
      <ProvideAuth>
        <Router>
          <Routes>
            <Route
              path={PATH.LOGIN}
              element={<LogInPage snackbar={snackbar} />}
            />
            <Route
              path={PATH.SIGNUP}
              element={<SignUpPage snackbar={snackbar} />}
            />
            <Route
              path={PATH.ROOT}
              element={
                <RequireAuth>
                  <MainPage snackbar={snackbar} />
                </RequireAuth>
              }
            />
          </Routes>
        </Router>
        <CustomSnackbar
          open={snackbar.open}
          snackbarState={snackbar.snackbarState}
        />
      </ProvideAuth>
    </ThemeProvider>
  );
}

/**
 * 認証情報が無い場合にログインページへリダイレクトするためのコンポーネント
 * @returns
 */
function RequireAuth({ children }: { children: JSX.Element }): JSX.Element {
  const auth = useAuth();
  const location = useLocation();
  if (!(auth.authUser && auth.authUser.emailVerified)) {
    return <Navigate to={PATH.LOGIN} state={{ from: location }} replace />;
  }
  return children;
}
