type YellowId = 0;
type YellowCode = "#FFA500";
type GreenId = 1;
type GreenCode = "#32CD32";
type RedId = 2;
type RedCode = "#FF0000";
type GrayId = 3;
type GrayCode = "#808080";
export type PerspectiveColorId = YellowId | GreenId | RedId | GrayId;
export type PerspectiveColorCode = YellowCode | GreenCode | RedCode | GrayCode;
export const PERSPECTIVE_COLOR: {
  YELLOW: { ID: YellowId; CODE: YellowCode };
  GREEN: { ID: GreenId; CODE: GreenCode };
  RED: { ID: RedId; CODE: RedCode };
  GRAY: { ID: GrayId; CODE: GrayCode };
} = {
  YELLOW: {
    ID: 0,
    CODE: "#FFA500",
  },
  GREEN: {
    ID: 1,
    CODE: "#32CD32",
  },
  RED: {
    ID: 2,
    CODE: "#FF0000",
  },
  GRAY: {
    ID: 3,
    CODE: "#808080",
  },
};

export const SUN = "日";
export const MON = "月";
export const TUE = "火";
export const WED = "水";
export const THU = "木";
export const FRI = "金";
export const SAT = "土";

export const CSV_IMPORT_LEARNERS_HEADER_1 = "学習者氏名";
export const CSV_IMPORT_LEARNERS_HEADER_2 = "学習者番号";
export const CSV_IMPORT_LEARNERS_HEADER_3 = "座席表_行";
export const CSV_IMPORT_LEARNERS_HEADER_4 = "座席表_列";
export const CSV_IMPORT_LEARNERS_HEADERS = [
  CSV_IMPORT_LEARNERS_HEADER_1,
  CSV_IMPORT_LEARNERS_HEADER_2,
  CSV_IMPORT_LEARNERS_HEADER_3,
  CSV_IMPORT_LEARNERS_HEADER_4,
];

export const CSV_EXPORT_LEARNERS_FILE_NAME = "学習者情報";
export const CSV_EXPORT_LEARNERS_TEMPLATE_FILE_NAME =
  "学習者情報インポート用テンプレート";

export const USERS_PATH = "rakurakuSheet/data/users";
export const RAKURAKU_AUTHORIZATION_URI =
  "https://eteachers.jp/api/v1/rakuraku_sheet";
