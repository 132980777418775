import { createTheme } from "@mui/material";

export const MUI_THEME = createTheme({
  palette: {
    secondary: {
      main: "#FFFFFF",
    },
  },
  typography: {
    h1: {
      fontWeight: 400,
      fontSize: "5rem",
      lineHeight: 1.3,
    },
    h2: {
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: 1.4,
    },
    h3: {
      fontSize: "2.5rem",
      fontWeight: 500,
      lineHeight: 1.6,
    },
    h5: {
      fontWeight: 500,
      lineHeight: 1.6,
    },
    h4: {
      fontSize: "2.3rem",
      lineHeight: 1.6,
    },
    button: {
      fontSize: "1rem",
    },
    body1: {
      lineHeight: 1.8,
    },
    body2: {
      lineHeight: 1.8,
    },
    subtitle1: {
      fontWeight: 500,
      lineHeight: 1.8,
    },
    subtitle2: {
      lineHeight: 1.6,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1.0rem",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "1.0rem",
        },
        h1: {
          fontWeight: 400,
          fontSize: "5rem",
          lineHeight: 1.3,
        },
        h2: {
          fontWeight: 400,
          fontSize: "3rem",
          lineHeight: 1.4,
        },
        h3: {
          fontSize: "2.5rem",
          fontWeight: 500,
          lineHeight: 1.6,
        },
        h5: {
          fontWeight: 500,
          lineHeight: 1.6,
        },
        h4: {
          fontSize: "2.3rem",
          lineHeight: 1.6,
        },
        h6: {
          fontSize: "1.8rem",
          lineHeight: 1.6,
        },
      },
    },
  },
});
