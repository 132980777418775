import { Add, Cancel, Circle } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
  Popper,
} from "@mui/material";
import React, { useRef, useState } from "react";

import { MESSAGES } from "../../message";
import { randomId } from "../../scripts/randomId";
import {
  BaseMenuItemAttr,
  BoolEvaluationItemStyle,
  CategoryEvaluationItemStyle,
  EvaluationItem,
  EvaluationItemTypes,
} from "../../types";
import { CancelButton } from "../buttons/CancelButton";
import { OkButton } from "../buttons/OkButton";
import { ColorPicker, PRESET_COLORS } from "../common/ColorPicker";
import { BaseSelectInput } from "../inputs/BaseSelectInput";
import {
  integerValidation,
  notEmptyValidation,
  ValidationTextField,
} from "../inputs/ValidationTextField";
import { SimpleMenuItem } from "../menu/SimpleMenuItem";

type AddEvaluationItemDialogProps = {
  open: boolean;
  onClickOK: (evaluationItem: EvaluationItem) => void;
  onClickCancel: () => void;
};

export function AddEvaluationItemDialog(
  props: AddEvaluationItemDialogProps
): JSX.Element {
  // 二値ボタン用
  const [boolEvaluationItemStyle, setBoolEvaluationItemStyle] =
    useState<BoolEvaluationItemStyle>({
      true: { label: "" },
      false: { label: "" },
    });
  const updateBoolEvaluationItemStyle = (key: string, value: string) => {
    if (key === "true" || key === "false") {
      boolEvaluationItemStyle[key] = { label: value };
      setBoolEvaluationItemStyle({ ...boolEvaluationItemStyle });
    }
  };

  // カテゴリーボタン用
  const [categoryEvaluationItemStyle, setCategoryEvaluationItemStyle] =
    useState<CategoryEvaluationItemStyle>({
      [randomId()]: { label: "", color: PRESET_COLORS[0] },
    });
  const updateCategoryEvaluationItemStyle = (
    key1: string,
    key2: "label" | "color",
    value: string
  ) => {
    categoryEvaluationItemStyle[key1][key2] = value;
    setCategoryEvaluationItemStyle({ ...categoryEvaluationItemStyle });
  };
  // popper用
  const inputElement = useRef<HTMLDivElement | null>(null);
  const [popperOpenId, setPopperOpenId] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // カウントボタン用
  const [countEvaluationItemRange, setCountEvaluationItemRange] = useState({
    min: "",
    max: "",
  });
  const updateCountEvaluationItemStyle = (
    key: "min" | "max",
    value: string
  ) => {
    countEvaluationItemRange[key] = value;
    setCountEvaluationItemRange({ ...countEvaluationItemRange });
  };

  // 3種ボタン共通
  const itemAttrs: Array<BaseMenuItemAttr> = [
    {
      id: "BOOL",
      label: "二値",
    },
    {
      id: "COUNT",
      label: "カウント",
    },
    {
      id: "CATEGORY",
      label: "カテゴリー",
    },
  ];

  const [evaluationItemType, setEvaluationItemType] =
    useState<EvaluationItemTypes>("BOOL");
  const [evaluationItemName, setEvaluationItemName] = useState<string>("");
  const [evaluationItemDefault, setEvaluationItemDefault] =
    useState<string>("");
  const [validationMode, setValidationMode] = useState<boolean>(false);
  const initInput = () => {
    // 入力欄初期化
    setValidationMode(false);
    setEvaluationItemType("BOOL");
    setEvaluationItemName("");
    setEvaluationItemDefault("");
    setBoolEvaluationItemStyle({
      true: { label: "" },
      false: { label: "" },
    });
    setCountEvaluationItemRange({ min: "", max: "" });
    setCategoryEvaluationItemStyle({
      [randomId()]: { label: "", color: PRESET_COLORS[0] },
    });
  };
  // バリデーション
  const defaultCheckValidation = {
    isValid: notEmptyValidation.isValid,
    errorMessage: MESSAGES.ERROR.INPUT.INVALID_EMPTY_DEFAULT_OPTION,
  };
  const minNumberValidation = {
    isValid: () => {
      return (
        Number(countEvaluationItemRange.min) <
        Number(countEvaluationItemRange.max)
      );
    },
    errorMessage: MESSAGES.ERROR.INPUT.INVALID_MIN_NUMBER,
  };
  const maxNumberValidation = {
    isValid: () => {
      return (
        Number(countEvaluationItemRange.min) <
        Number(countEvaluationItemRange.max)
      );
    },
    errorMessage: MESSAGES.ERROR.INPUT.INVALID_MAX_NUMBER,
  };
  const defaultNumberValidation = {
    isValid: () => {
      return (
        Number(countEvaluationItemRange.min) <= Number(evaluationItemDefault) &&
        Number(countEvaluationItemRange.max) >= Number(evaluationItemDefault)
      );
    },
    errorMessage: MESSAGES.ERROR.INPUT.INVALID_DEFAULT_NUMBER,
  };
  const noDupCategoryOptionValidation = {
    isValid: (inputValue?: string) => {
      const styleArray = Object.entries(categoryEvaluationItemStyle).map(
        ([, style]) => style
      );
      const dupStyles = styleArray.filter(
        (style) => style.label === inputValue
      );
      return dupStyles.length === 1;
    },
    errorMessage: MESSAGES.ERROR.INPUT.INVALID_DUP_OPTION,
  };
  const noDupBoolOptionValidation = {
    isValid: () => {
      return (
        boolEvaluationItemStyle.true.label !==
        boolEvaluationItemStyle.false.label
      );
    },
    errorMessage: MESSAGES.ERROR.INPUT.INVALID_DUP_OPTION,
  };

  // 評価タイプの選択肢が変更された際の処理
  const handleChangeEvaluationItemType = (
    selectedEvaluationItemType: string
  ) => {
    initInput();
    (selectedEvaluationItemType === "BOOL" ||
      selectedEvaluationItemType === "COUNT" ||
      selectedEvaluationItemType === "CATEGORY") &&
      setEvaluationItemType(selectedEvaluationItemType);
  };

  // popperを閉じる際の処理
  const handleClosePopper = () => {
    setPopperOpenId(null); // popperを閉じる。
    setAnchorEl(null); // popperを閉じる。
  };

  // ダイアログが閉じられた際の処理
  const handleCloseDialog = () => {
    initInput();
    props.onClickCancel();
    handleClosePopper();
  };

  // カテゴリーボタン設定項目の選択肢削除ボタンをクリックした際の処理
  const handleClickDeleteCategoryOption = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // 削除行の各設定値をstateから削除する。
    const newCategoryEvaluationItemStyle = { ...categoryEvaluationItemStyle };
    delete newCategoryEvaluationItemStyle[e.currentTarget.id];
    setCategoryEvaluationItemStyle(newCategoryEvaluationItemStyle);
    if (e.currentTarget.id === evaluationItemDefault) {
      setEvaluationItemDefault("");
    }
  };

  // カテゴリーボタン設定項目の選択肢追加ボタンをクリックした際の処理
  const handleClickAddNewCategoryOption = () => {
    const keyLength = Object.keys(categoryEvaluationItemStyle).length;
    const presetColorIndex = keyLength % PRESET_COLORS.length;

    // 新しい設定行をstateに追加する。
    setCategoryEvaluationItemStyle({
      ...categoryEvaluationItemStyle,
      [randomId()]: { label: "", color: PRESET_COLORS[presetColorIndex] },
    });
  };

  // OKボタンがクリックされたときの処理
  const handleClickOk = () => {
    handleClosePopper();
    setValidationMode(true);
    const id = randomId();
    switch (evaluationItemType) {
      case "BOOL": {
        if (
          // 入力値の確認
          notEmptyValidation.isValid(evaluationItemName) &&
          defaultCheckValidation.isValid(evaluationItemDefault) &&
          notEmptyValidation.isValid(boolEvaluationItemStyle.true.label) &&
          notEmptyValidation.isValid(boolEvaluationItemStyle.false.label) &&
          noDupBoolOptionValidation.isValid()
        ) {
          const evaluationItem: EvaluationItem = {
            id: id,
            name: evaluationItemName,
            type: evaluationItemType,
            default: evaluationItemDefault === "true",
            style: boolEvaluationItemStyle,
          };
          props.onClickOK(evaluationItem);
          handleCloseDialog();
        }
        break;
      }
      case "COUNT": {
        if (
          // 入力値の確認
          notEmptyValidation.isValid(evaluationItemName) &&
          defaultCheckValidation.isValid(evaluationItemDefault) &&
          notEmptyValidation.isValid(countEvaluationItemRange.min) &&
          notEmptyValidation.isValid(countEvaluationItemRange.max) &&
          minNumberValidation.isValid() &&
          maxNumberValidation.isValid() &&
          defaultNumberValidation.isValid()
        ) {
          const evaluationItem: EvaluationItem = {
            id: id,
            name: evaluationItemName,
            type: evaluationItemType,
            default: Number(evaluationItemDefault),
            range: {
              min: Number(countEvaluationItemRange.min),
              max: Number(countEvaluationItemRange.max),
            },
          };
          props.onClickOK(evaluationItem);
          handleCloseDialog();
        }
        break;
      }
      case "CATEGORY": {
        // 入力値の確認
        const inValidStyle = Object.fromEntries(
          Object.entries(categoryEvaluationItemStyle).filter(
            ([, style]) =>
              !notEmptyValidation.isValid(style.label) ||
              !noDupCategoryOptionValidation.isValid(style.label)
          )
        );
        const isValidStyle = Object.keys(inValidStyle).length === 0;
        if (
          notEmptyValidation.isValid(evaluationItemName) &&
          defaultCheckValidation.isValid(evaluationItemDefault) &&
          isValidStyle
        ) {
          const evaluationItem: EvaluationItem = {
            id: id,
            name: evaluationItemName,
            type: evaluationItemType,
            default: evaluationItemDefault,
            style: categoryEvaluationItemStyle,
          };
          props.onClickOK(evaluationItem);
          handleCloseDialog();
        }
        break;
      }
    }
  };

  return (
    <Dialog open={props.open} onClose={handleCloseDialog}>
      <DialogTitle>{"評価項目追加"}</DialogTitle>
      <DialogContent sx={{ width: "31em", minHeight: "30em" }}>
        {/* 評価タイプの選択 */}
        <BaseSelectInput
          label={"評価タイプ"}
          selectedValue={evaluationItemType}
          menuItemAttrs={itemAttrs}
          menuItemComponent={SimpleMenuItem}
          lineDivide={false}
          onChange={handleChangeEvaluationItemType}
        />

        {/* 評価項目名の入力欄 */}
        <ValidationTextField
          label={"評価項目名"}
          value={evaluationItemName}
          type={"text"}
          validationMode={validationMode}
          validations={[notEmptyValidation]}
          maxLength={50}
          onChange={(attr) => setEvaluationItemName(attr.value)}
        />

        {/* 二値ボタン用の設定項目 */}
        {evaluationItemType === "BOOL" && (
          <>
            <FormLabel sx={{ marginTop: 3 }} component="legend">
              {"選択肢の設定"}
            </FormLabel>
            <FormLabel
              error={
                validationMode &&
                !defaultCheckValidation.isValid(evaluationItemDefault)
              }
            >
              {defaultCheckValidation.errorMessage}
            </FormLabel>
            {Object.keys(boolEvaluationItemStyle).map((id, index) => (
              <Box key={index} sx={{ display: "flex" }}>
                {/* デフォルト値設定チェックボックス */}
                <Checkbox
                  id={id}
                  size="small"
                  sx={{ marginTop: 3 }}
                  checked={evaluationItemDefault === id}
                  onChange={(e) => {
                    if (e.target.id === id && e.target.checked) {
                      setEvaluationItemDefault(e.target.id);
                    }
                  }}
                />
                {/* 表示名入力欄 */}
                <ValidationTextField
                  id={id}
                  label={`選択肢${index + 1}の表示名`}
                  value={
                    id === "true" || id === "false"
                      ? boolEvaluationItemStyle[id].label
                      : ""
                  }
                  type={"text"}
                  validationMode={validationMode}
                  validations={[notEmptyValidation, noDupBoolOptionValidation]}
                  maxLength={50}
                  onChange={(attr) =>
                    updateBoolEvaluationItemStyle(attr.id, attr.value)
                  }
                />
              </Box>
            ))}
          </>
        )}

        {/* カウントボタン用の設定項目 */}
        {evaluationItemType === "COUNT" && (
          <>
            <ValidationTextField
              label={"最小値"}
              value={countEvaluationItemRange.min}
              type={"number"}
              defaultHelperText={MESSAGES.ERROR.INPUT.INVALID_MIN_NUMBER}
              validationMode={validationMode}
              validations={[integerValidation, minNumberValidation]}
              maxLength={6}
              onChange={(attr) =>
                updateCountEvaluationItemStyle("min", attr.value)
              }
            />
            <ValidationTextField
              label={"最大値"}
              value={countEvaluationItemRange.max}
              type={"number"}
              defaultHelperText={MESSAGES.ERROR.INPUT.INVALID_MAX_NUMBER}
              validationMode={validationMode}
              validations={[integerValidation, maxNumberValidation]}
              maxLength={6}
              onChange={(attr) =>
                updateCountEvaluationItemStyle("max", attr.value)
              }
            />
            <ValidationTextField
              label={"初期値"}
              value={
                typeof evaluationItemDefault === "string"
                  ? evaluationItemDefault
                  : ""
              }
              type={"number"}
              defaultHelperText={MESSAGES.ERROR.INPUT.INVALID_DEFAULT_NUMBER}
              validationMode={validationMode}
              validations={[integerValidation, defaultNumberValidation]}
              maxLength={6}
              onChange={(attr) => setEvaluationItemDefault(attr.value)}
            />
          </>
        )}

        {/* カテゴリボタン用の設定項目 */}
        {evaluationItemType === "CATEGORY" && (
          <>
            <FormLabel sx={{ marginTop: 3 }} component="legend">
              {"選択肢の設定"}
            </FormLabel>
            <FormLabel
              error={
                validationMode &&
                !defaultCheckValidation.isValid(evaluationItemDefault)
              }
            >
              {defaultCheckValidation.errorMessage}
            </FormLabel>
            {Object.keys(categoryEvaluationItemStyle).map((id, index) => (
              <Box key={index} sx={{ display: "flex" }}>
                {/* デフォルト値設定チェックボックス */}
                <Checkbox
                  id={id}
                  size="small"
                  sx={{ marginTop: 3 }}
                  checked={evaluationItemDefault === id}
                  onChange={(e) => {
                    if (e.target.id === id && e.target.checked) {
                      setEvaluationItemDefault(e.target.id);
                    }
                  }}
                />
                {/* 表示名入力欄 */}
                <ValidationTextField
                  id={id}
                  label={`選択肢${index + 1}の表示名`}
                  value={categoryEvaluationItemStyle[id]["label"]}
                  type={"text"}
                  validationMode={validationMode}
                  validations={[
                    notEmptyValidation,
                    noDupCategoryOptionValidation,
                  ]}
                  maxLength={50}
                  onChange={(attr) =>
                    updateCategoryEvaluationItemStyle(
                      attr.id,
                      "label",
                      attr.value
                    )
                  }
                />
                {/* カラー設定ボタン */}
                <IconButton
                  id={id}
                  sx={{ marginTop: 3 }}
                  onClick={(e) => {
                    setPopperOpenId(e.currentTarget.id);
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  <Circle
                    sx={{
                      color: categoryEvaluationItemStyle[id]["color"],
                    }}
                  />
                </IconButton>
                {/* カラーピッカー */}
                <Popper
                  id={id}
                  open={popperOpenId === id}
                  anchorEl={anchorEl}
                  placement="right-start"
                  style={{ zIndex: 1500 }}
                >
                  <ClickAwayListener onClickAway={handleClosePopper}>
                    {/* ClickAwayListenerが機能するように参照を移す */}
                    <div ref={inputElement}>
                      <ColorPicker
                        onChange={(colorHex) => {
                          anchorEl &&
                            updateCategoryEvaluationItemStyle(
                              anchorEl.id,
                              "color",
                              colorHex
                            );
                          handleClosePopper();
                        }}
                      />
                    </div>
                  </ClickAwayListener>
                </Popper>
                {/* 選択肢削除ボタン */}
                <IconButton
                  id={id}
                  sx={{ marginTop: 3 }}
                  onClick={handleClickDeleteCategoryOption}
                >
                  <Cancel />
                </IconButton>
              </Box>
            ))}

            {/* 選択肢追加ボタン */}
            <IconButton
              sx={{ marginTop: 3 }}
              onClick={handleClickAddNewCategoryOption}
            >
              <Add />
            </IconButton>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <OkButton onClick={handleClickOk} />
        <CancelButton onClick={handleCloseDialog} />
      </DialogActions>
    </Dialog>
  );
}
