import { Button } from "@mui/material";
import React from "react";

type OkButtonProps = {
  disabled?: boolean;
  onClick: () => void;
};

/**
 * OKButton
 * @param props
 * @returns
 */
export function OkButton(props: OkButtonProps) {
  const handleClick = () => {
    props.onClick();
  };
  return (
    <Button
      onClick={handleClick}
      variant="contained"
      color={"primary"}
      disabled={props.disabled}
    >
      {"OK"}
    </Button>
  );
}
