import { Box, styled } from "@mui/system";

export const BaseSeatNameStyled = styled(Box)({
  //text項目
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bold",
  color: "white",
  textTransform: "none",
  fontFamily:
    '"Noto Sans JP","FP-ヒラギノUD角ゴ StdN W4", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Yu Gothic", "Meiryo", sans-serif',

  //block項目
  width: "90%",
  height: "50%",
  position: "absolute",
  top: "3%",
  left: "0",
  right: "0",
  margin: "auto",
});

export const BaseSeatLabelStyled = styled(Box)({
  //text項目
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bold",
  color: "white",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "none",
  fontFamily:
    '"Noto Sans JP","FP-ヒラギノUD角ゴ StdN W4", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Yu Gothic", "Meiryo", sans-serif',

  //brock項目
  width: "80%",
  height: "40%",
  position: "absolute",
  top: "55%",
  left: "0",
  right: "0",
  margin: "auto",
});
