import { Divider, MenuItem, MenuList, Paper } from "@mui/material";
import React from "react";

import { BaseMenuItemAttr } from "../../types";

type MenuItemComponentProps<T extends BaseMenuItemAttr> = {
  id: string;
  menuItemAttr: T;
  onClick: (itemId: string) => void;
};

type BaseMenuListProps<T extends BaseMenuItemAttr> = {
  menuItemAttrs: Array<T>;
  menuItemComponent: (props: MenuItemComponentProps<T>) => JSX.Element;
  lineDivide: boolean;
  onClickMenuItem: (itemId: string) => void;
};
export function BaseMenuList<T extends BaseMenuItemAttr>(
  props: BaseMenuListProps<T>
): JSX.Element {
  return (
    <Paper sx={{ minWidth: "120px" }}>
      <MenuList>
        {props.menuItemAttrs !== undefined ? (
          props.menuItemAttrs.map((menuItemAttr, index) => {
            // 線を引くかどうかの判定を行う。
            const withLine =
              props.lineDivide && props.menuItemAttrs.length - 1 !== index;
            return withLine ? (
              [
                <props.menuItemComponent
                  key={menuItemAttr.id}
                  id={menuItemAttr.id}
                  menuItemAttr={menuItemAttr}
                  onClick={props.onClickMenuItem}
                />,
                <Divider key={`${menuItemAttr.id}-divider`} />,
              ]
            ) : (
              <props.menuItemComponent
                key={menuItemAttr.id}
                id={menuItemAttr.id}
                menuItemAttr={menuItemAttr}
                onClick={props.onClickMenuItem}
              />
            );
          })
        ) : (
          <MenuItem key={"empty"}></MenuItem>
        )}
      </MenuList>
    </Paper>
  );
}
