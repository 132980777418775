import { AddCircleOutline } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";

import { MESSAGES } from "../../message";
import { EvaluationItem } from "../../types";
import { AddEvaluationItemDialog } from "../dialogs/AddEvaluationItemDialog";

type AddEvaluationItemButtonProps = {
  onClickOK: (evaluationItem: EvaluationItem) => void;
};

export function AddEvaluationItemButton(
  props: AddEvaluationItemButtonProps
): JSX.Element {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Tooltip title={MESSAGES.TOOLTIPS.ADD_EVALUATION_ITEM} arrow>
        <IconButton
          onClick={() => {
            setOpen(true);
          }}
        >
          <AddCircleOutline />
        </IconButton>
      </Tooltip>
      <AddEvaluationItemDialog
        open={open}
        onClickOK={props.onClickOK}
        onClickCancel={() => {
          setOpen(false);
        }}
      />
    </>
  );
}
