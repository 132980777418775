import { Box } from "@mui/material";
import React from "react";

import { BaseSeatNameStyled } from "./customButtonStyled";

type BaseSeatNameProps = {
  name: string;
};

/**
 * 座席ボタンに名前を表示するコンポーネント
 * display:flexとtextOverFlowを併用する為の記述
 * @param name
 * @returns
 */
export function BaseSeatName(props: BaseSeatNameProps) {
  return (
    <BaseSeatNameStyled>
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {props.name}
      </Box>
    </BaseSeatNameStyled>
  );
}
