import { Cancel } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  DialogContentText,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";

import { MESSAGES } from "../../message";
import { BaseDialog } from "../dialogs/BaseDialog";

type EvaluationItemButtonProps = {
  id: string;
  label: string;
  selected: boolean;
  onClick: (selectedId: string) => void;
  onClickDelete: (selectedId: string) => void;
};
export function EvaluationItemButton(
  props: EvaluationItemButtonProps
): JSX.Element {
  const [openDialogId, setOpenDialogId] = useState<string>("");
  const [deleteId, setDeleteId] = useState<string>("");

  // ボタン本体部分をクリックした際の処理
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    props.onClick(e.currentTarget.id);
  };

  // 削除アイコンをクリックした際の処理
  const handleClickDeleteIcon = (e: React.MouseEvent<HTMLButtonElement>) => {
    setDeleteId(e.currentTarget.id);
    setOpenDialogId("deleteConfirm");
  };

  // 削除確認ダイアログでOKボタンをクリックした際の処理
  const handleClickDeleteOk = () => {
    props.onClickDelete(deleteId);
  };

  return (
    <>
      <ButtonGroup
        size={"small"}
        variant={"outlined"}
        sx={{
          border: "2px solid",
          borderColor: "primary.main",
          backgroundColor: props.selected ? "WHITE" : "primary.main",
          borderRadius: "50em",
        }}
      >
        <Button
          id={props.id}
          size="small"
          variant={"text"}
          onClick={handleClick}
          sx={{
            textTransform: "none",
            paddingLeft: "1.5em",
            color: props.selected ? "primary.main" : "WHITE",
            borderTopLeftRadius: "50em",
            borderBottomLeftRadius: "50em",
          }}
        >
          {props.label}
        </Button>
        <Tooltip title={MESSAGES.TOOLTIPS.REMOVE_EVALUATION_ITEM} arrow>
          <IconButton
            id={props.id}
            size="small"
            sx={{
              color: props.selected ? "GRAY" : "WHITE",
            }}
            onClick={handleClickDeleteIcon}
          >
            <Cancel fontSize="small" />
          </IconButton>
        </Tooltip>
      </ButtonGroup>
      <DeleteConfirmDialog
        openDialogId={openDialogId}
        evaluationItemName={props.label}
        onClickOk={handleClickDeleteOk}
        onClickCancel={() => setOpenDialogId("")}
      />
    </>
  );
}

type DeleteConfirmDialogProps = {
  openDialogId: string;
  evaluationItemName: string;
  onClickOk: () => void;
  onClickCancel: () => void;
};
function DeleteConfirmDialog(props: DeleteConfirmDialogProps) {
  return (
    <BaseDialog
      open={props.openDialogId === "deleteConfirm"}
      label={"評価項目の削除"}
      onOkClick={props.onClickOk}
      onClose={props.onClickCancel}
    >
      <DialogContentText>
        {`${props.evaluationItemName}
        ${MESSAGES.INFO.DELETE.CONFIRM_DELETE_EVALUATION_ITEM}`}
      </DialogContentText>
    </BaseDialog>
  );
}
