import { TextField } from "@mui/material";
import React from "react";

import { MESSAGES } from "../../message";

export type ValidationTextFieldAttr = {
  id: string;
  value: string;
};

type ValidationTextFieldProps = {
  id?: string;
  label: string;
  value: string;
  type: "number" | "text" | "date";
  defaultHelperText?: string;
  validationMode: boolean;
  validations?: Array<{
    isValid: (inputValue?: string) => boolean;
    errorMessage: string;
  }>;
  maxLength: number;
  onChange: (attr: ValidationTextFieldAttr) => void;
};

export function ValidationTextField(props: ValidationTextFieldProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.currentTarget.id;
    const value = event.currentTarget.value;
    if (props.type === "number" && value.length <= props.maxLength) {
      props.onChange({ id: id, value: value });
    } else if (props.type === "text" || props.type === "date") {
      props.onChange({ id: id, value: value });
    }
  };

  const errorValidation =
    props.validationMode && props.validations
      ? props.validations.find((validation) => !validation.isValid(props.value))
      : undefined;
  const errorMessage = errorValidation?.errorMessage;

  return (
    <TextField
      id={props.id}
      label={props.label}
      value={props.value}
      type={props.type}
      error={errorMessage !== undefined}
      helperText={
        errorMessage !== undefined ? errorMessage : props.defaultHelperText
      }
      inputProps={{ minLength: 1, maxLength: props.maxLength }}
      onChange={handleChange}
      fullWidth
      margin="normal"
      variant="standard"
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}

export const notEmptyValidation = {
  isValid: (inputValue?: string) =>
    inputValue !== undefined && inputValue !== null && inputValue !== "",
  errorMessage: MESSAGES.ERROR.INPUT.INVALID_EMPTY_TEXT,
};

export const numberValidation = {
  isValid: (inputValue?: string) =>
    notEmptyValidation.isValid(inputValue) && !isNaN(Number(inputValue)),
  errorMessage: MESSAGES.ERROR.INPUT.INVALID_NUMBER,
};

export const integerValidation = {
  isValid: (inputValue?: string) =>
    numberValidation.isValid(inputValue) &&
    Number.isInteger(Number(inputValue)),
  errorMessage: MESSAGES.ERROR.INPUT.INVALID_INTEGER,
};
export const dateValidation = {
  isValid: (inputValue?: string) => {
    return (
      inputValue !== undefined &&
      notEmptyValidation.isValid(inputValue) &&
      new Date(inputValue) !== new Date("invalid date")
    );
  },
  errorMessage: MESSAGES.ERROR.INPUT.INVALID_DATE,
};
