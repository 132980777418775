import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

import { CancelButton } from "../buttons/CancelButton";
import { OkButton } from "../buttons/OkButton";

type SimpleDialogProps = {
  open: boolean;
  title?: string;
  text: string;
  onClickOk?: () => void;
  onClickCancel?: () => void;
  onClose?: () => void;
};

export function SimpleDialog(props: SimpleDialogProps): JSX.Element {
  return (
    <Dialog
      sx={{ flexDirection: "column" }}
      onClose={props.onClose}
      open={props.open}
    >
      {props.title !== undefined && <DialogTitle>{props.title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{props.text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.onClickOk && <OkButton onClick={props.onClickOk} />}
        {props.onClickCancel && <CancelButton onClick={props.onClickCancel} />}
      </DialogActions>
    </Dialog>
  );
}
