import {
  Help,
  HelpOutline,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  MobileStepper,
  Tooltip,
} from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React from "react";

import { MESSAGES } from "../../../message";

type TutorialWithStepperProps = {
  outlined: boolean;
  steps: Array<JSX.Element>;
  open: boolean;
};
export function TutorialWithStepper(
  props: React.PropsWithChildren<TutorialWithStepperProps>
): JSX.Element {
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(props.open);

  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
  };

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <Tooltip title={MESSAGES.TOOLTIPS.TUTORIAL} arrow>
        <IconButton
          color={"secondary"}
          aria-label="help-button"
          onClick={handleClick}
          sx={{ marginRight: 3 }}
        >
          {props.outlined ? <HelpOutline /> : <Help />}
        </IconButton>
      </Tooltip>

      <Dialog fullWidth id={id} open={open} onClose={handleClose}>
        <DialogContent
          sx={{
            minHeight: 400,
          }}
        >
          <StepperForTutorial
            steps={props.steps}
            activeStep={activeStep}
            onClickNext={() => setActiveStep(activeStep + 1)}
            onClickBack={() => setActiveStep(activeStep - 1)}
            onClickClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

type StepperForTutorialProps = {
  steps: Array<JSX.Element>;
  activeStep: number;
  onClickNext: () => void;
  onClickBack: () => void;
  onClickClose: () => void;
};
function StepperForTutorial(props: StepperForTutorialProps) {
  const theme = useTheme();
  const maxSteps = props.steps.length;

  return (
    <Box
      sx={{
        display: "flow",
      }}
    >
      {props.steps[props.activeStep]}

      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={props.activeStep}
        sx={{
          width: "90%",
          position: "absolute",
          left: "5%",
          bottom: 0,
        }}
        nextButton={
          props.activeStep === maxSteps - 1 ? (
            <Button size="small" onClick={props.onClickClose}>
              Close
            </Button>
          ) : (
            <Button
              size="small"
              onClick={props.onClickNext}
              disabled={props.activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          )
        }
        backButton={
          <Button
            size="small"
            onClick={props.onClickBack}
            disabled={props.activeStep === 0}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}
