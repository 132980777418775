import { Box, Typography } from "@mui/material";
import React from "react";

import { MESSAGES } from "../../../message";
import { TutorialWithStepper } from "./TutorialWithStepperButton";

type TutorialAppProps = {
  firstLogin: boolean;
};

export function TutorialApp(props: TutorialAppProps) {
  return (
    <TutorialWithStepper
      outlined={true}
      steps={steps()}
      open={props.firstLogin}
    />
  );
}

function steps(): Array<JSX.Element> {
  return [
    <Page1 key="tutorial-page-1" />,
    <Page2 key="tutorial-page-2" />,
    <Page3 key="tutorial-page-3" />,
    <Page4 key="tutorial-page-4" />,
    <Page5 key="tutorial-page-5" />,
    <Page6 key="tutorial-page-6" />,
    <Page7 key="tutorial-page-7" />,
    <Page8 key="tutorial-page-8" />,
  ];
}

function Page1() {
  return (
    <Box sx={{ height: "100%", display: "block" }}>
      <Typography variant="h6" sx={{ textAlign: "center", m: 1, padding: 4 }}>
        {MESSAGES.TUTORIAL.INTRODUCTION.TITLE}
      </Typography>
      <Typography variant="subtitle1" sx={{ textAlign: "center", m: 4 }}>
        {MESSAGES.TUTORIAL.INTRODUCTION.SUBTITLE}
      </Typography>
      {MESSAGES.TUTORIAL.INTRODUCTION.MAIN.map((text, index) => (
        <Typography
          key={`main_${index}`}
          variant="subtitle2"
          sx={{ textAlign: "center" }}
        >
          {text}
        </Typography>
      ))}
    </Box>
  );
}

function Page2() {
  return (
    <Box sx={{ height: "100%" }}>
      <Typography variant="h6" sx={{ textAlign: "center", m: 1 }}>
        {MESSAGES.TUTORIAL.STEP1.TITLE}
      </Typography>
      <div style={{ width: "100%", textAlign: "center" }}>
        <img
          src={`${process.env.PUBLIC_URL}/images/tutorial_1.png`}
          alt="tutorial_1.png"
          style={{
            width: "35%",
            height: "50%",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </div>
      <Typography variant="subtitle1" sx={{ textAlign: "center", m: 2 }}>
        {MESSAGES.TUTORIAL.STEP1.MAIN}
      </Typography>
    </Box>
  );
}

function Page3() {
  return (
    <Box sx={{ height: "100%" }}>
      <Typography variant="h6" sx={{ textAlign: "center", m: 1 }}>
        {MESSAGES.TUTORIAL.STEP2.TITLE}
      </Typography>
      <div style={{ width: "100%", textAlign: "center" }}>
        <img
          src={`${process.env.PUBLIC_URL}/images/tutorial_2.png`}
          alt="tutorial_2.png"
          style={{
            width: "35%",
            height: "50%",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </div>
      <Typography variant="subtitle1" sx={{ textAlign: "center", m: 2 }}>
        {MESSAGES.TUTORIAL.STEP2.MAIN}
      </Typography>
    </Box>
  );
}
function Page4() {
  return (
    <Box sx={{ height: "100%" }}>
      <Typography variant="h6" sx={{ textAlign: "center", m: 1 }}>
        {MESSAGES.TUTORIAL.STEP3.TITLE}
      </Typography>
      <div style={{ width: "100%", textAlign: "center" }}>
        <img
          src={`${process.env.PUBLIC_URL}/images/tutorial_3.png`}
          alt="tutorial_3.png"
          style={{
            width: "35%",
            height: "50%",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </div>
      <Typography variant="subtitle1" sx={{ textAlign: "center", m: 2 }}>
        {MESSAGES.TUTORIAL.STEP3.MAIN}
      </Typography>
    </Box>
  );
}
function Page5() {
  return (
    <Box sx={{ height: "100%" }}>
      <Typography variant="h6" sx={{ textAlign: "center", m: 1 }}>
        {MESSAGES.TUTORIAL.STEP4.TITLE}
      </Typography>
      <div style={{ width: "100%", textAlign: "center" }}>
        <img
          src={`${process.env.PUBLIC_URL}/images/tutorial_4.png`}
          alt="tutorial_4.png"
          style={{
            width: "35%",
            height: "50%",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </div>
      {MESSAGES.TUTORIAL.STEP4.MAIN.map((text, index) => (
        <Typography
          key={`main_${index}`}
          variant="subtitle2"
          sx={{ textAlign: "center" }}
        >
          {text}
        </Typography>
      ))}
    </Box>
  );
}

function Page6() {
  return (
    <Box sx={{ height: "100%" }}>
      <Typography variant="h6" sx={{ textAlign: "center", m: 1 }}>
        {MESSAGES.TUTORIAL.STEP5.TITLE}
      </Typography>
      <div style={{ width: "100%", textAlign: "center" }}>
        <img
          src={`${process.env.PUBLIC_URL}/images/tutorial_5.png`}
          alt="tutorial_5.png"
          style={{
            width: "35%",
            height: "50%",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </div>
      {MESSAGES.TUTORIAL.STEP4.MAIN.map((text, index) => (
        <Typography
          key={`main_${index}`}
          variant="subtitle2"
          sx={{ textAlign: "center" }}
        >
          {text}
        </Typography>
      ))}
    </Box>
  );
}
function Page7() {
  return (
    <Box sx={{ height: "100%" }}>
      <Typography variant="h6" sx={{ textAlign: "center", m: 1 }}>
        {MESSAGES.TUTORIAL.STEP6.TITLE}
      </Typography>
      <div style={{ width: "100%", textAlign: "center" }}>
        <img
          src={`${process.env.PUBLIC_URL}/images/tutorial_6.png`}
          alt="tutorial_6.png"
          style={{
            width: "35%",
            height: "50%",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </div>
      <Typography
        variant="subtitle1"
        sx={{ textAlign: "center", marginTop: 2 }}
      >
        {MESSAGES.TUTORIAL.STEP6.MAIN}
      </Typography>
    </Box>
  );
}

function Page8() {
  return (
    <Box sx={{ height: "100%" }}>
      <Typography variant="h6" sx={{ textAlign: "center", m: 1, padding: 1 }}>
        {MESSAGES.TUTORIAL.CONCLUSION.TITLE}
      </Typography>
      <div style={{ width: "100%", textAlign: "center" }}>
        <img
          src={`${process.env.PUBLIC_URL}/images/conclusion.png`}
          alt="conclusion.png"
          style={{
            width: "35%",
            height: "50%",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </div>
      <Typography
        variant="subtitle1"
        sx={{ textAlign: "center", marginTop: 2 }}
      >
        {MESSAGES.TUTORIAL.CONCLUSION.MAIN}
      </Typography>
    </Box>
  );
}
