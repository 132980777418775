import { Button, Card } from "@mui/material";
import React from "react";

import { BoolEvaluationItemStyle } from "../../types";
import { BaseSeatName } from "./styled/BaseSeat";
import { BaseSeatLabelStyled } from "./styled/customButtonStyled";

type BoolButtonProps = {
  name: string;
  value: boolean;
  style: BoolEvaluationItemStyle;
  isDragging: boolean;
  onClick: (boolValue: boolean) => void;
};

export function BoolSeatButton(props: BoolButtonProps): JSX.Element {
  const handleClick = () => {
    if (!props.isDragging) {
      props.onClick(!props.value);
    }
  };

  const buttonColor = {
    trueColor: "#32CD32",
    falseColor: "#FF0000",
  };

  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#1976d2",
      }}
    >
      <BaseSeatName name={props.name} />
      <BaseSeatLabelStyled
        sx={{
          backgroundColor: props.value
            ? buttonColor.trueColor
            : buttonColor.falseColor,
        }}
      >
        {props.value ? props.style.true.label : props.style.false.label}
      </BaseSeatLabelStyled>
      <Button
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          padding: 0,
          minWidth: 20,
          flexDirection: "column",
          backgroundColor: "transparent",
        }}
        onClick={handleClick}
      ></Button>
    </Card>
  );
}
