import { Box, MenuItem, TextField } from "@mui/material";
import React from "react";

import { FRI, MON, SAT, SUN, THU, TUE, WED } from "../configs/config";
import { Classroom } from "../types";
import { AddClassroomButton } from "./buttons/AddClassroomButton";
import { RemoveCurrentClassroomButton } from "./buttons/RemoveCurrentClassroomButton";

type ClassroomInfoProps = {
  currentClassroom: Classroom;
  classrooms: Array<Classroom>;
  onChange: (newCurrentClassroomId: string) => void;
  onAdd: (newClassroom: Classroom) => void;
  onRemove: (removeClassroomId: string) => void;
};

export function ClassroomInfo(props: ClassroomInfoProps): JSX.Element {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value);
  };

  const itemAttrs = props.classrooms.map((classroom) => {
    const year = classroom.date.getFullYear();
    const month = classroom.date.getMonth() + 1;
    const day = classroom.date.getDate();
    const dayOfWeek = classroom.date.getDay();
    const dayOfWeekStr = [SUN, MON, TUE, WED, THU, FRI, SAT][dayOfWeek];
    const ret = {
      id: classroom.id,
      label: `${classroom.name} ${year}年${month}月${day}日 (${dayOfWeekStr}) ${classroom.period}限`,
    };
    return ret;
  });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "end",
      }}
    >
      <TextField
        select
        value={props.currentClassroom.id}
        InputProps={{
          style: { fontSize: "2em", height: "1em" },
        }}
        variant="standard"
        size="small"
        margin="normal"
        onChange={handleChange}
      >
        {itemAttrs.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <AddClassroomButton
        currentClassroom={props.currentClassroom}
        onAddNewClassroom={props.onAdd}
      />
      <RemoveCurrentClassroomButton
        id={props.currentClassroom.id}
        label={props.currentClassroom.name}
        onRemove={props.onRemove}
      />
    </Box>
  );
}
