import "../gateways/fireBaseSettings";

import type { User } from "firebase/auth";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";

import { addUser, getRakurakuAuthorization, readUser } from "../gateways/api";
import { RakurakuUser } from "../types";

/**
 * useAuthの実装については以下を参考
 * https://usehooks.com/useAuth/
 */
interface AuthContextState {
  authUser: User | null;
  rakurakuUser: RakurakuUser | undefined;
  getAuthorization: (email: string | null) => Promise<boolean>;
  firstLoginState: boolean;
  emailSignUp: (email: string, password: string) => Promise<void>;
  emailLogIn: (email: string, password: string) => Promise<User | null>;
  googleLogIn: () => Promise<User | null>;
  logOut: () => Promise<void>;
}
const authContext = createContext({} as AuthContextState);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth(
  props: React.PropsWithChildren<Record<string, unknown>>
): JSX.Element {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>{props.children}</authContext.Provider>
  );
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = (): AuthContextState => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [authUser, setAuthUser] = useState<User | null>(null);
  const [rakurakuUser, setRakurakuUser] = useState<RakurakuUser | undefined>(
    undefined
  );
  const [firstLoginState, setFirstLoginState] = useState(false);

  /**
   * サインアウト処理を実行する関数
   */
  const logOut = async () => {
    const auth = getAuth();
    await signOut(auth);
    setAuthUser(null);
  };

  /**
   * メールアドレス/パスワード認証を利用した登録処理を行う関数
   * 確認のメールも飛ばす。
   */
  const emailSignUp = async (email: string, password: string) => {
    try {
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await sendEmailVerification(userCredential.user);
      setAuthUser(userCredential.user);
    } catch (error) {
      if (error instanceof Error) {
        logOut();
        throw error;
      }
    }
  };

  /**
   * メールアドレス/パスワード認証を利用したログイン処理を実行する関数
   */
  const emailLogIn = async (
    email: string,
    password: string
  ): Promise<User | null> => {
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      await readRakurakuUser(userCredential.user);
      return userCredential.user;
    } catch (error) {
      if (error instanceof Error) {
        logOut();
        throw error;
      }
    }
    return null;
  };

  /**
   * Google認証を利用したログイン処理を実行する関数
   */
  const googleLogIn = async (): Promise<User | null> => {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      await readRakurakuUser(result.user);
      return result.user;
    } catch (error) {
      if (error instanceof Error) {
        logOut();
        throw error;
      }
    }
    return null;
  };

  /**
   * ログインユーザーの認可情報を取得する。
   */
  const getAuthorization = async (email: string | null) => {
    const rakurakuAuthorization = await getRakurakuAuthorization(email);
    return rakurakuAuthorization;
  };

  /**
   * dbに保存されているuserデータを読みset関数に設定する。
   * userデータが無ければ新レコードを登録する。
   */
  const readRakurakuUser = async (user: User) => {
    const uid = user.uid;
    const mailAddress = user.email;
    // 認可が無ければ処理終了
    const rakurakuAuthorization = await getAuthorization(mailAddress);
    if (!rakurakuAuthorization) return;
    if (user.emailVerified) {
      const readedRakurakuUser = await readUser(uid);
      if (readedRakurakuUser !== undefined) {
        setRakurakuUser(readedRakurakuUser);
      } else {
        setRakurakuUser(await addUser(uid, mailAddress));
        setFirstLoginState(true);
      }
    }
    setAuthUser(user);
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (newUserState) => {
      if (newUserState) {
        setAuthUser(newUserState);
      } else {
        setAuthUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    authUser,
    rakurakuUser,
    getAuthorization,
    firstLoginState,
    emailSignUp,
    emailLogIn,
    googleLogIn,
    logOut,
  };
}
