import { GroupRemove } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";

import { MESSAGES } from "../../message";
import { Learner } from "../../types";
import { SelectRemoveLearnerDialog } from "../dialogs/SelectRemoveLearnerDialog";

type SelectRemoveLearnerButtonProps = {
  learners: Array<Learner>;
  onSelectRemoveLearner: (selectRemoveLearnerIds: Array<string>) => void;
};

export function SelectRemoveLearnerButton(
  props: SelectRemoveLearnerButtonProps
) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOkClick = (selectRemoveLearnerIds: Array<string>) => {
    props.onSelectRemoveLearner(selectRemoveLearnerIds);
  };

  return (
    <>
      <Tooltip title={MESSAGES.TOOLTIPS.REMOVE_LEARNER} arrow>
        <span>
          <IconButton
            onClick={handleClick}
            disabled={props.learners.length === 0}
          >
            <GroupRemove fontSize="large" />
          </IconButton>
        </span>
      </Tooltip>

      <SelectRemoveLearnerDialog
        learners={props.learners}
        open={open}
        onOkClick={handleOkClick}
        onClose={handleClose}
      />
    </>
  );
}
