import { Cancel } from "@mui/icons-material";
import { DialogContentText, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";

import { MESSAGES } from "../../message";
import { BaseDialog } from "../dialogs/BaseDialog";

type RemoveCurrentClassroomButtonProps = {
  id: string;
  label: string;
  onRemove: (selectedId: string) => void;
};
export function RemoveCurrentClassroomButton(
  props: RemoveCurrentClassroomButtonProps
): JSX.Element {
  const [open, setOpen] = useState(false);

  // 削除確認ダイアログでOKボタンをクリックした際の処理
  const handleClickDeleteOk = () => {
    props.onRemove(props.id);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={MESSAGES.TOOLTIPS.REMOVE_CLASSROOM} arrow>
        <IconButton id={props.id} size="small" onClick={() => setOpen(true)}>
          <Cancel />
        </IconButton>
      </Tooltip>

      {/* 削除確認ダイアログ */}
      <BaseDialog
        open={open}
        label={"授業の削除"}
        onOkClick={handleClickDeleteOk}
        onClose={() => setOpen(false)}
      >
        <DialogContentText>
          {`${props.label}${MESSAGES.INFO.DELETE.CONFIRM_DELETE_CLASSROOM}`}
        </DialogContentText>
      </BaseDialog>
    </>
  );
}
