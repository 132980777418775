import { useEffect, useRef, useState } from "react";

type WindowSize = {
  width: number;
  height: number;
};
export function useWindowResize(): WindowSize {
  const [width, setWidth] = useState(document.body.clientWidth);
  const [height, setHeight] = useState(document.body.clientHeight);

  const listener = () => {
    setWidth(document.body.clientWidth);
    setHeight(document.body.clientHeight);
  };

  // 初回レンダー判定用
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      setWidth(document.body.clientWidth);
    } else {
      isFirstRender.current = false;
    }
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  return {
    width: width,
    height: height,
  };
}

// https://codesandbox.io/s/509jvrqr8n?from-embed=&file=/src/useWindowResize.js:0-501
