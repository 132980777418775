import { ListItemText } from "@mui/material";
import React from "react";

import { BaseMenuItemAttr } from "../../types";

type SimpleMenuItemProps = {
  menuItemAttr: BaseMenuItemAttr;
  onClick?: (itemId: string) => void;
};
export function SimpleMenuItem(props: SimpleMenuItemProps): JSX.Element {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    props.onClick && props.onClick(e.currentTarget.id);
  };

  return (
    <ListItemText primary={props.menuItemAttr.label} onClick={handleClick} />
  );
}
