import { Button, Card, ClickAwayListener, Popper } from "@mui/material";
import React, { useEffect, useState } from "react";

import { CategoryEvaluationItemStyle } from "../../types";
import { BaseMenuList } from "../menu/BaseMenuList";
import {
  MenuItemWithCircleIcon,
  MenuItemWithCircleIconAttr,
} from "../menu/MenuItemWithCircleIcon";
import { BaseSeatName } from "./styled/BaseSeat";
import { BaseSeatLabelStyled } from "./styled/customButtonStyled";

type CategorySeatButtonProps = {
  name: string;
  value: string;
  style: CategoryEvaluationItemStyle;
  isDragging: boolean;
  onClickCategory: (selectedCategoryValue: string) => void;
  onChangeDraggableState: (isDraggable: boolean) => void; // popper部分で座席をドラッグさせないため
};
export function CategorySeatButton(
  props: CategorySeatButtonProps
): JSX.Element {
  const [popperOpen, setPopperOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // 座席ボタンドラッグ時の処理
  useEffect(() => {
    if (props.isDragging) {
      setPopperOpen(false); // popperを閉じる。
    }
  }, [props.isDragging]);

  // 座席ボタンをクリックした時の処理
  const handleClickSeat = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!props.isDragging) {
      setPopperOpen(!popperOpen); // popperを開く。
      setAnchorEl(e.currentTarget); // クリックした座席ボタンをpopperの開く対象に設定する。
    }
  };

  // 座席ボタン以外をクリックした時の処理
  const handleClickAwaySeat = () => {
    setPopperOpen(false); // popperを閉じる。
    setAnchorEl(null); // popperの開く対象をリセットする。
  };

  // popperの選択肢をクリック時したときの処理
  const handleClickPopper = (selectedCategoryValue: string) => {
    props.onClickCategory(selectedCategoryValue); // 選択したカテゴリを通知する。&& ドラッグを有効にする。
    setPopperOpen(false); // popperを閉じる。
    props.onChangeDraggableState(true); // ドラッグを有効にする。
  };

  // popperにマウスオーバーしたときの処理
  const handleMouseOverPopper = () => {
    props.onChangeDraggableState(false); // ドラッグを無効にする。
  };

  // popperにマウスアウトしたときの処理
  const handleMouseOutPopper = () => {
    props.onChangeDraggableState(true); // ドラッグを有効にする。
  };

  // props.styleをMenuList用に変形
  const itemAttrs: Array<MenuItemWithCircleIconAttr> = Object.keys(
    props.style
  ).map((key) => ({
    id: key,
    label: props.style[key].label,
    iconColor: props.style[key].color,
  }));

  return (
    <ClickAwayListener onClickAway={handleClickAwaySeat}>
      <Card
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#1976d2",
        }}
      >
        <BaseSeatName name={props.name} />
        <BaseSeatLabelStyled
          sx={{
            backgroundColor: props.style[props.value].color,
          }}
        >
          {props.style[props.value].label}
        </BaseSeatLabelStyled>

        <Button
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            padding: 0,
            flexDirection: "column",
            backgroundColor: "transparent",
          }}
          onClick={handleClickSeat}
        ></Button>
        {/* popperの選択肢の表示 */}
        <Popper
          open={popperOpen}
          anchorEl={anchorEl}
          placement="right-start"
          onMouseOver={handleMouseOverPopper}
          onMouseOut={handleMouseOutPopper}
        >
          <BaseMenuList
            menuItemAttrs={itemAttrs}
            menuItemComponent={MenuItemWithCircleIcon}
            lineDivide={true}
            onClickMenuItem={handleClickPopper}
          />
        </Popper>
      </Card>
    </ClickAwayListener>
  );
}
