import { Circle } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import React from "react";

import { BaseMenuItemAttr } from "../../types";

export type MenuItemWithCircleIconAttr = BaseMenuItemAttr & {
  iconColor: string;
};
type MenuItemWithCircleIconProps = {
  id: string;
  menuItemAttr: MenuItemWithCircleIconAttr;
  onClick: (itemId: string) => void;
};
export function MenuItemWithCircleIcon(
  props: MenuItemWithCircleIconProps
): JSX.Element {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    props.onClick(e.currentTarget.id);
  };

  return (
    <MenuItem key={props.id} id={props.id} onClick={handleClick}>
      <ListItemIcon>
        <Circle fontSize="small" sx={{ color: props.menuItemAttr.iconColor }} />
      </ListItemIcon>
      <ListItemText>{props.menuItemAttr.label}</ListItemText>
    </MenuItem>
  );
}
