import { PersonAddAlt1 } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";

import { MESSAGES } from "../../message";
import { Classroom, Learner } from "../../types";
import { AddLearnerDialog } from "../dialogs/AddLearnerDialog";

type AddLearnerButtonProps = {
  currentClassroom: Classroom;
  onAddNewLearner: (newLearner: Array<Learner>) => void;
};

/**
 * ダイアログからのデータを受け取り、新規Learnerを作成するコンポーネント
 * @param props
 * @returns
 */
export function AddLearnerButton(props: AddLearnerButtonProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOk = (newLearner: Learner) => {
    props.onAddNewLearner([newLearner]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={MESSAGES.TOOLTIPS.ADD_LEARNER} arrow>
        <IconButton
          onClick={() => {
            setOpen(true);
          }}
        >
          <PersonAddAlt1 fontSize="large" />
        </IconButton>
      </Tooltip>

      <AddLearnerDialog
        open={open}
        currentClassroom={props.currentClassroom}
        onClose={handleClose}
        onClickOk={handleClickOk}
      />
    </>
  );
}
