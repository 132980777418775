import { Publish } from "@mui/icons-material";
import { ClickAwayListener, IconButton, Popper, Tooltip } from "@mui/material";
import React, { useRef, useState } from "react";

import {
  CSV_IMPORT_LEARNERS_HEADER_1,
  CSV_IMPORT_LEARNERS_HEADER_2,
  CSV_IMPORT_LEARNERS_HEADER_3,
  CSV_IMPORT_LEARNERS_HEADER_4,
  CSV_IMPORT_LEARNERS_HEADERS,
} from "../../configs/config";
import { MESSAGES } from "../../message";
import { randomId } from "../../scripts/randomId";
import { Classroom, Learner, LearnerEvaluationItemValue } from "../../types";
import {
  CSVImportResult,
  ImportCSVDropZone,
} from "../common/ImportCSVDropZone";
import { CustomSnackbarState } from "../CustomSnackbar";
import {
  integerValidation,
  notEmptyValidation,
} from "../inputs/ValidationTextField";

type BaseImportedLearner = {
  [CSV_IMPORT_LEARNERS_HEADER_1]: string;
  [CSV_IMPORT_LEARNERS_HEADER_2]: string;
  [CSV_IMPORT_LEARNERS_HEADER_3]: string;
  [CSV_IMPORT_LEARNERS_HEADER_4]: string;
  [learnerEvaluationItemValue: string]: string;
};

type ImportCSVButtonProps = {
  currentClassroom: Classroom;
  disabled?: boolean;
  snackbar: CustomSnackbarState;
  onImportLearners: (leaners: Array<Learner>) => void;
};
export function ImportCSVButton<T extends BaseImportedLearner>(
  props: ImportCSVButtonProps
) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const inputElement = useRef<HTMLDivElement | null>(null);

  // csvのヘッダー情報
  const evaluationItemNames = props.currentClassroom.evaluationItems.map(
    (evaluationItem) => evaluationItem.name
  );
  const headers = [...CSV_IMPORT_LEARNERS_HEADERS, ...evaluationItemNames];
  const caption = MESSAGES.INFO.IMPORT.LEARNERS_CSV_DROPZONE;
  const importConfirm = MESSAGES.INFO.IMPORT.CONFIRM_IMPORT_LEARNERS_CSV;

  // バリデーション
  const noDupLearnerValidation = {
    isValid: (
      importResultDatas: Array<BaseImportedLearner>,
      currentimportedData: BaseImportedLearner
    ) => {
      const dupLearners = importResultDatas.filter(
        (importResultData) =>
          importResultData[CSV_IMPORT_LEARNERS_HEADER_1] ===
            currentimportedData[CSV_IMPORT_LEARNERS_HEADER_1] &&
          importResultData[CSV_IMPORT_LEARNERS_HEADER_2] ===
            currentimportedData[CSV_IMPORT_LEARNERS_HEADER_2]
      );
      return dupLearners.length === 1;
    },
    errorMessage: MESSAGES.ERROR.IMPORT.INVALID_DUP_LEARNER,
  };

  const handleClickIcon = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleImportCSV = (importResult: CSVImportResult<T>) => {
    try {
      if (!importResult.isValid)
        throw Error(
          `${MESSAGES.ERROR.IMPORT.INVALID_HEADER}
          期待する項目名：${headers.join(", ")}`
        );

      const newLearners = importResult.data.map((importedData) => {
        // csvの基本項目のバリデーション
        if (
          !notEmptyValidation.isValid(
            importedData[CSV_IMPORT_LEARNERS_HEADER_1]
          )
        ) {
          throw Error(
            `${CSV_IMPORT_LEARNERS_HEADER_1}${MESSAGES.ERROR.IMPORT.INVALID_EMPTY_TEXT}`
          );
        } else if (
          !integerValidation.isValid(
            importedData[CSV_IMPORT_LEARNERS_HEADER_2]
          ) ||
          Number(importedData[CSV_IMPORT_LEARNERS_HEADER_2]) <= 0
        ) {
          throw Error(
            `${CSV_IMPORT_LEARNERS_HEADER_2}${MESSAGES.ERROR.IMPORT.INVALID_NATURAL_NUMBER}
            生徒名："${importedData[CSV_IMPORT_LEARNERS_HEADER_1]}"
            入力値："${importedData[CSV_IMPORT_LEARNERS_HEADER_2]}"`
          );
        } else if (
          !noDupLearnerValidation.isValid(importResult.data, importedData)
        ) {
          throw Error(
            `${MESSAGES.ERROR.IMPORT.INVALID_DUP_LEARNER}
            生徒名："${importedData[CSV_IMPORT_LEARNERS_HEADER_1]}"
            番号："${importedData[CSV_IMPORT_LEARNERS_HEADER_2]}"`
          );
        } else if (
          !integerValidation.isValid(
            importedData[CSV_IMPORT_LEARNERS_HEADER_3]
          ) ||
          Number(importedData[CSV_IMPORT_LEARNERS_HEADER_3]) < 0
        ) {
          throw Error(
            `${CSV_IMPORT_LEARNERS_HEADER_3}${MESSAGES.ERROR.IMPORT.INVALID_POSITIVE_INTEGER}
            生徒名："${importedData[CSV_IMPORT_LEARNERS_HEADER_1]}"
            入力値："${importedData[CSV_IMPORT_LEARNERS_HEADER_3]}"`
          );
        } else if (
          !integerValidation.isValid(
            importedData[CSV_IMPORT_LEARNERS_HEADER_4]
          ) ||
          Number(importedData[CSV_IMPORT_LEARNERS_HEADER_4]) < 0
        ) {
          throw Error(
            `${CSV_IMPORT_LEARNERS_HEADER_4}${MESSAGES.ERROR.IMPORT.INVALID_POSITIVE_INTEGER}
            生徒名："${importedData[CSV_IMPORT_LEARNERS_HEADER_1]}"
            入力値："${importedData[CSV_IMPORT_LEARNERS_HEADER_4]}"`
          );
        }

        // csvの評価項目の値をLearnerデータに設定できるように変換する。ついでにバリデーションもする。
        const evaluationItemKVArray: Array<
          [string, number | string | boolean]
        > = props.currentClassroom.evaluationItems.map((evaluationItem) => {
          const importedValue = importedData[evaluationItem.name];
          // 入力値をもとにidに変換する。存在しないlabelだったらエラーメッセージを出して処理を中断する。
          switch (evaluationItem.type) {
            case "COUNT":
              if (
                !integerValidation.isValid(importedValue) ||
                !(
                  evaluationItem.range.min <= Number(importedValue) &&
                  evaluationItem.range.max >= Number(importedValue)
                )
              ) {
                throw Error(
                  `${evaluationItem.name}${MESSAGES.ERROR.IMPORT.INVALID_RANGE_INTEGER}
                  生徒名："${importedData[CSV_IMPORT_LEARNERS_HEADER_1]}"
                  入力値："${importedValue}"
                  最小値："${evaluationItem.range.min}"
                  最大値："${evaluationItem.range.max}"`
                );
              }
              return [evaluationItem.id, Number(importedValue)];
            default: {
              // 入力値に一致する評価項目のラベルを検索しデータを変換する。
              const targetEvaluationItemStyle = Object.entries(
                evaluationItem.style
              ).find(([, style]) => importedValue === style.label);
              if (!targetEvaluationItemStyle)
                throw Error(
                  `${evaluationItem.name}${MESSAGES.ERROR.IMPORT.INVALID_OPTION}
                  生徒名："${importedData[CSV_IMPORT_LEARNERS_HEADER_1]}"
                  入力値："${importedValue}"`
                );
              const [styleId] = targetEvaluationItemStyle;
              if (evaluationItem.type === "CATEGORY") {
                return [evaluationItem.id, styleId];
              } else {
                return styleId === "true"
                  ? [evaluationItem.id, true]
                  : [evaluationItem.id, false];
              }
            }
          }
        });
        const newLearnerEvaluationItemsValue: LearnerEvaluationItemValue<
          number | string | boolean
        > = Object.fromEntries(evaluationItemKVArray);

        // csvから取り込まれた各データをLearnerに設定
        const newLearner: Learner = {
          id: randomId(),
          name: importedData[CSV_IMPORT_LEARNERS_HEADER_1],
          number: Number(importedData[CSV_IMPORT_LEARNERS_HEADER_2]),
          coord: {
            row: Number(importedData[CSV_IMPORT_LEARNERS_HEADER_3]),
            col: Number(importedData[CSV_IMPORT_LEARNERS_HEADER_4]),
          },
          evaluationItems: newLearnerEvaluationItemsValue,
        };
        return newLearner;
      });
      // 正常終了した際に上のコンポーネントに通知する。
      props.onImportLearners(newLearners);
      handleClose();
      props.snackbar.displaySuccessMessage(MESSAGES.INFO.IMPORT.SUCCESS);
    } catch (e) {
      handleClose();
      if (e instanceof Error) props.snackbar.displayErrorMessage(e.message);
    }
  };

  return (
    <>
      <Tooltip title={MESSAGES.TOOLTIPS.FILE_IMPORT} arrow>
        <IconButton
          disabled={props.disabled ?? false}
          onClick={handleClickIcon}
        >
          <Publish fontSize="large" />
        </IconButton>
      </Tooltip>
      <Popper open={open} anchorEl={anchorEl} placement="bottom">
        <ClickAwayListener onClickAway={handleClose}>
          <div ref={inputElement}>
            <ImportCSVDropZone
              csvHeaders={headers}
              caption={caption}
              dialogMessage={importConfirm}
              onImportCSV={handleImportCSV}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
