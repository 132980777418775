import { DialogContentText, Paper } from "@mui/material";
import Encoding from "encoding-japanese";
import Papa, { ParseResult } from "papaparse";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";

import { MESSAGES } from "../../message";
import { BaseDialog } from "../dialogs/BaseDialog";

/**
 * CSVのインポート結果
 * 正常に取り込みが完了した場合のみ有効な値を返却する
 */
export type CSVImportResult<T extends object> =
  | {
      data: Array<T>;
      isValid: true;
    }
  | {
      data: Array<unknown>;
      isValid: false;
    };

type ImportCSVDropZoneProps<T extends object> = {
  csvHeaders: Array<string>;
  caption: string;
  dialogMessage: string;
  onImportCSV: (importResult: CSVImportResult<T>) => void;
};
/**
 * CSVの取り込みを行うボタン。ヘッダー行が不正では無いかの検証も行う。
 * @param  props
 *  csvHeaders      : 取り込むCSVのヘッダー行を指定
 *  caption         : ドロップゾーン内に表示する文字列。
 *  onImportCSV     : インポート後に実行する処理。引数としてインポート結果を渡す。
 * @returns
 */
export function ImportCSVDropZone<T extends object>(
  props: ImportCSVDropZoneProps<T>
) {
  const [acceptedFileName, setAcceptedFileName] = useState<string | undefined>(
    undefined
  );
  const [acceptedFileData, setAcceptedFileData] = useState<
    ParseResult<T | unknown> | undefined
  >(undefined);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  // 入力ファイルが変更される度にインポートを行うかの確認ダイアログを表示する。
  useEffect(() => {
    if (acceptedFileData !== undefined) {
      setDialogOpen(true);
    }
  }, [acceptedFileData]);

  // ダイアログが閉じられた際初期化する。
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setAcceptedFileName(undefined);
    setAcceptedFileData(undefined);
  };

  // ダイアログでOKを押した際に上のコンポーネントにインポートしたデータを通知する。
  const handleClickOKDialog = () => {
    setDialogOpen(false);
    setAcceptedFileName(undefined);
    setAcceptedFileData(undefined);
    if (!acceptedFileData) return;

    // バリデーションチェックを行う
    if (validateCSVHeader<T>(props.csvHeaders, acceptedFileData)) {
      // 有効なCSVをインポートした場合
      props.onImportCSV({
        data: acceptedFileData.data,
        isValid: true,
      });
    } else {
      // 無効なCSVをインポートした場合
      props.onImportCSV({
        data: acceptedFileData.data,
        isValid: false,
      });
    }
  };

  // ファイルを受け付けたときの処理
  const handleDropAccepted = (acceptedFile: Array<File>) => {
    const reader = new FileReader();
    reader.addEventListener("load", handleLoad);
    reader.readAsArrayBuffer(acceptedFile[0]);
    setAcceptedFileName(acceptedFile[0].name);
  };

  // ファイル読み込みの処理
  const handleLoad = (e: ProgressEvent<FileReader>) => {
    if (
      e.target === null ||
      e.target.result === null ||
      typeof e.target.result === "string"
    ) {
      return;
    }
    // header行をkey,2行目以降をデータとみなしてパースする。
    const codes = new Uint8Array(e.target.result);
    const detectedCode = Encoding.detect(codes);
    if (typeof detectedCode === "boolean") {
      return;
    }
    const unicodeString = Encoding.convert(codes, {
      to: "UNICODE",
      from: detectedCode,
      type: "string",
    });
    // インポートした内容からBOMを取り除く。
    const unicodeStringWithoutBOM =
      unicodeString.charCodeAt(0) === 0xfeff
        ? unicodeString.slice(1)
        : unicodeString;
    const resultCSVImport = Papa.parse(unicodeStringWithoutBOM, {
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,
    });
    setAcceptedFileData(resultCSVImport);
  };

  return (
    <>
      <Dropzone
        onDropAccepted={handleDropAccepted}
        accept=".csv"
        multiple={false}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <Paper
            {...getRootProps()}
            sx={{
              width: "25em",
              height: "10em",
              border: "1px dotted #888",
              paddingLeft: "1em",
              paddingRight: "1em",
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>{MESSAGES.INFO.IMPORT.FILE_DROP}</p>
            ) : acceptedFileName !== undefined ? (
              <p>{acceptedFileName}</p>
            ) : (
              <p>{props.caption}</p>
            )}
          </Paper>
        )}
      </Dropzone>
      <BaseDialog
        label="csvファイルのインポート"
        open={dialogOpen}
        onOkClick={handleClickOKDialog}
        onClose={handleCloseDialog}
      >
        <DialogContentText>{props.dialogMessage}</DialogContentText>
      </BaseDialog>
    </>
  );
}

/**
 * インポートしたCSVのヘッダーが規定通りの並び順であるかを検証する関数
 * @param headers
 * @param importedCSV
 * @returns {boolean} 規定通りな並びの場合にはtrueを、そうでない場合はfalseを返却する。
 */
export function validateCSVHeader<T extends object>(
  headers: Array<string>,
  importedCSV: ParseResult<unknown>
): importedCSV is ParseResult<T> {
  const importedCSVFields = importedCSV.meta.fields;
  if (importedCSVFields === undefined) {
    return false;
  }
  if (headers.length !== importedCSVFields.length) {
    return false;
  }
  for (let i = 0; i < headers.length; i++) {
    if (headers[i] !== importedCSVFields[i]) {
      return false;
    }
  }
  return true;
}
