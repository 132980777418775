import { Checkbox, List, ListItem } from "@mui/material";
import React from "react";

import { BaseMenuItemAttr } from "../../types";

type BaseCheckListProps<T extends BaseMenuItemAttr> = {
  listItemAttrs: Array<T>;
  createListItemComponent: (listItem: T) => JSX.Element;
  onClickListItem: (itemId: Array<string>) => void;
};

export function BaseCheckList<T extends BaseMenuItemAttr>(
  props: BaseCheckListProps<T>
): JSX.Element {
  const [checkedItems, setCheckedItems] = React.useState<Array<string>>([]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const itemId = e.currentTarget.id;
    const currentIndex = checkedItems.indexOf(itemId);
    const newChecked = [...checkedItems];

    if (currentIndex === -1) {
      newChecked.push(itemId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedItems(newChecked);
  };

  React.useEffect(() => {
    props.onClickListItem(checkedItems);
  }, [checkedItems, props]);
  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        position: "relative",
        overflow: "auto",
        maxHeight: 300,
      }}
    >
      {props.listItemAttrs !== undefined ? (
        props.listItemAttrs.map((listItemAttr) => (
          <ListItem key={listItemAttr.id} id={listItemAttr.id}>
            <Checkbox
              id={listItemAttr.id}
              edge={"start"}
              onChange={handleChange}
              checked={checkedItems.indexOf(listItemAttr.id) !== -1}
            />
            {props.createListItemComponent(listItemAttr)}
          </ListItem>
        ))
      ) : (
        <ListItem key={"empty"}></ListItem>
      )}
    </List>
  );
}
