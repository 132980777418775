import { Download } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { Parser } from "json2csv";
import React, { useState } from "react";
import { CSVLink } from "react-csv";

import {
  CSV_EXPORT_LEARNERS_FILE_NAME,
  CSV_EXPORT_LEARNERS_TEMPLATE_FILE_NAME,
  CSV_IMPORT_LEARNERS_HEADERS,
} from "../../configs/config";
import { MESSAGES } from "../../message";
import { Classroom } from "../../types";

type ExportCSVButtonProps = {
  currentClassroom: Classroom;
};
export function ExportCSVButton(props: ExportCSVButtonProps) {
  // csvファイル名
  const year = props.currentClassroom.date.getFullYear();
  const month = `0${props.currentClassroom.date.getMonth() + 1}`.slice(-2);
  const day = `0${props.currentClassroom.date.getDate()}`.slice(-2);
  const csvFileName = `${year}-${month}-${day}_${props.currentClassroom.name}_${CSV_EXPORT_LEARNERS_FILE_NAME}`;
  const csvTemplateFileName = `${year}-${month}-${day}_${props.currentClassroom.name}_${CSV_EXPORT_LEARNERS_TEMPLATE_FILE_NAME}`;

  // csvのヘッダー情報
  const evaluationItemNames = props.currentClassroom.evaluationItems.map(
    (evaluationItem) => evaluationItem.name
  );
  const headers = [...CSV_IMPORT_LEARNERS_HEADERS, ...evaluationItemNames].map(
    (header) => `${header.replace(/"/g, '""')}`
  );
  const doubleQuoteHeaders = headers.map((header) => `"${header}"`);

  // csv出力内容
  const exportData = props.currentClassroom.learners.map((learner) => {
    const exportEvaluationItems = props.currentClassroom.evaluationItems.map(
      (evaluationItem) => {
        const value = learner.evaluationItems[evaluationItem.id];
        switch (typeof value) {
          case "boolean": {
            if (evaluationItem.type === "BOOL") {
              return value
                ? evaluationItem.style["true"].label
                : evaluationItem.style["false"].label;
            }
            break;
          }
          case "number":
            return value;
          case "string":
            return evaluationItem.type === "CATEGORY"
              ? evaluationItem.style[value].label
              : undefined;
          default:
            return undefined;
        }
        return undefined;
      }
    );
    const exportLearner = [
      learner.name,
      learner.number,
      learner.coord.row,
      learner.coord.col,
      ...exportEvaluationItems,
    ];
    return exportLearner;
  });

  // 配列をcsvの文字列に変換
  const json2csvParser = new Parser({ header: false });
  const exportDataString =
    exportData.length > 0 ? json2csvParser.parse(exportData) : [];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleClickIcon = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const handleClickCSVLink = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={MESSAGES.TOOLTIPS.FILE_EXPORT} arrow>
        <IconButton onClick={handleClickIcon}>
          <Download fontSize="large" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <CSVLink
            headers={headers}
            data={[]}
            filename={csvTemplateFileName}
            onClick={handleClickCSVLink}
          >
            {"学習者情報インポート用テンプレートファイルのダウンロード"}
          </CSVLink>
        </MenuItem>
        <MenuItem>
          <CSVLink
            headers={exportData.length > 0 ? doubleQuoteHeaders : headers}
            data={exportDataString}
            filename={csvFileName}
            onClick={handleClickCSVLink}
          >
            {"現在表示されている学習者情報のダウンロード"}
          </CSVLink>
        </MenuItem>
      </Menu>
    </>
  );
}
