import React from "react";

import { MESSAGES } from "../../message";
import { getNowDateStr } from "../../scripts/getDateToString";
import { randomId } from "../../scripts/randomId";
import { Classroom } from "../../types";
import {
  dateValidation,
  integerValidation,
  notEmptyValidation,
  ValidationTextField,
  ValidationTextFieldAttr,
} from "../inputs/ValidationTextField";
import { BaseDialog } from "./BaseDialog";

type AddClassroomDialogProps = {
  open: boolean;
  currentClassroom: Classroom | undefined;
  onClose: () => void;
  onClickOk: (newClassroom: Classroom) => void;
};

export function AddClassroomDialog(props: AddClassroomDialogProps) {
  //新規授業入力管理用State
  const [newClassroomName, setNewClassroomName] = React.useState("");
  const [newClassroomDate, setNewClassroomDate] = React.useState(
    getNowDateStr()
  );
  const [newClassroomPeriod, setNewClassroomPeriod] = React.useState("");
  const [validationMode, setValidationMode] = React.useState<boolean>(false);

  //バリデーション
  const naturalNumberValidation = {
    isValid: (inputValue?: string) => {
      return integerValidation.isValid(inputValue) && Number(inputValue) > 0;
    },
    errorMessage: MESSAGES.ERROR.INPUT.INVALID_NATURAL_NUMBER,
  };

  //リセット処理
  React.useEffect(() => {
    setValidationMode(false);
    setNewClassroomName(
      props.currentClassroom ? props.currentClassroom.name : ""
    );
    setNewClassroomPeriod("");
    setNewClassroomDate(getNowDateStr());
  }, [props.open, props.currentClassroom]);

  const handleOkClick = () => {
    setValidationMode(true);
    //入力値チェック
    if (
      notEmptyValidation.isValid(newClassroomName) &&
      naturalNumberValidation.isValid(newClassroomPeriod) &&
      dateValidation.isValid(newClassroomDate)
    ) {
      //入力ドームを元に新規作成した学習者データ
      const newClassroom: Classroom = {
        id: randomId(),
        name: newClassroomName,
        date: new Date(newClassroomDate),
        period: Number(newClassroomPeriod),
        learners:
          props.currentClassroom !== undefined
            ? props.currentClassroom.learners.map((learner) => {
                const newLearner = { ...learner };
                newLearner.id = randomId();
                newLearner.evaluationItems = {};
                return newLearner;
              })
            : [],
        evaluationItems: [],
      };
      props.onClickOk(newClassroom);
      props.onClose();
    }
  };

  const handleClassroomNameChange = (
    changeClassroomName: ValidationTextFieldAttr
  ) => {
    setNewClassroomName(changeClassroomName.value);
  };

  const handleClassroomPeriodChange = (
    changeClassroomPeriod: ValidationTextFieldAttr
  ) => {
    setNewClassroomPeriod(changeClassroomPeriod.value);
  };

  const handleClassroomDateChange = (
    changeClassroomDate: ValidationTextFieldAttr
  ) => {
    setNewClassroomDate(changeClassroomDate.value);
  };

  return (
    <>
      <BaseDialog
        label="新規授業追加"
        open={props.open}
        onOkClick={handleOkClick}
        onClose={props.onClose}
      >
        <ValidationTextField
          label={"授業名"}
          value={newClassroomName}
          type={"text"}
          validationMode={validationMode}
          validations={[notEmptyValidation]}
          maxLength={20}
          onChange={handleClassroomNameChange}
        />
        <ValidationTextField
          label={"授業日"}
          value={newClassroomDate}
          type={"date"}
          validationMode={validationMode}
          validations={[dateValidation]}
          maxLength={10}
          onChange={handleClassroomDateChange}
        />
        <ValidationTextField
          label={"時限"}
          defaultHelperText={MESSAGES.ERROR.INPUT.INVALID_NATURAL_NUMBER}
          value={newClassroomPeriod}
          type={"number"}
          validationMode={validationMode}
          validations={[naturalNumberValidation]}
          maxLength={2}
          onChange={handleClassroomPeriodChange}
        />
      </BaseDialog>
    </>
  );
}
