import { Box } from "@mui/system";
import React, { useState } from "react";
import ReactGridLayout, { Layout } from "react-grid-layout";

import { useWindowResize } from "../customHooks/useWindowResize";
import { EvaluationItem, Learner } from "../types";
import { SeatButton } from "./buttons/SeatButton";
type SeatButtonLayoutProps = {
  learners: Array<Learner>;
  evaluationItem?: EvaluationItem;
  onChangeLearners: (learners: Array<Learner>) => void;
};

/**
 *ReactgridLayoutW:H割合設定
 */
const GRID_WIDTH_RATE = 3;
const GRID_HEIGHT_RATE = 2;
const SEAT_WIDTH_RATE = 12;
const SEAT_HEIGHT_RATE = 3.5;

/**
 * 生徒の座席をViewに配置するコンポーネント
 * @param props: SeatButtonLayoutProps
 * @returns <ResponsiveReactGridLayout/>
 */
export function SeatButtonLayout(props: SeatButtonLayoutProps) {
  /**
   * 座席移動時にクリックイベントの発行を防ぐ為のstate
   */
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggable, setIsDraggable] = useState(true);

  /**
   * 座標更新時のLearerを更新する。
   * @param newLayouts
   */
  const handleDragStop = (newLayouts: Array<Layout>) => {
    const newLearners = [...props.learners];
    newLearners.forEach((learner) => {
      const newLayout = newLayouts.find((layout) => layout.i === learner.id);
      if (newLayout) {
        learner.coord = { col: newLayout.x, row: newLayout.y };
      }
    });
    props.onChangeLearners(newLearners);
    setTimeout(() => {
      setIsDragging(false);
    }, 150);
  };

  /**
   * 座席ボタン押下時のハンドラ
   * @param learner
   */
  const handleClick = (learner: Learner) => {
    props.onChangeLearners([learner]);
    setIsDraggable(true);
  };

  const handleDragSeatButton = () => {
    setIsDragging(true);
  };

  const handleChangeDraggableState = (isDraggableState: boolean) => {
    setIsDraggable(isDraggableState);
  };

  /**
   * 座席ボタン配置用
   * @param currentLearner
   * @returns
   */
  const currentSeatButton = (currentLearner: Learner) => {
    return (
      <SeatButton
        learner={currentLearner}
        evaluationItem={props.evaluationItem}
        isDragging={isDragging}
        onClick={handleClick}
        onChangeDraggableState={handleChangeDraggableState}
      />
    );
  };

  /**
   * reactgridlayoutウインドウ幅調整用項目
   */
  const windowSize = useWindowResize();
  const GRID_N_COLS = 120;
  const GRID_MAX_ROWS = 40;
  const GRID_MIN_WIDTH = 1200;
  const gridWidthSize =
    GRID_MIN_WIDTH > windowSize.width ? GRID_MIN_WIDTH : windowSize.width;
  const gridGap = (gridWidthSize / GRID_N_COLS) * GRID_WIDTH_RATE;

  const gridHeightSize = gridGap / GRID_HEIGHT_RATE;

  /**
   * GridLayout格子の色
   */
  const GRID_BORDER_COLOR = "#808080";

  /**
   * 座席配置用state
   */
  const seatLayout = props.learners.map(convertToSeatLayout);

  return (
    <Box sx={{ width: "100%", height: "100%", overflow: "auto" }}>
      <Box
        sx={{
          width: gridWidthSize,
          height: gridHeightSize * (GRID_MAX_ROWS + 1),
          backgroundImage: `linear-gradient(0deg, transparent calc(100% - 1px), ${GRID_BORDER_COLOR} calc(100% - 1px)),
        linear-gradient(90deg, transparent calc(100% - 1px), ${GRID_BORDER_COLOR} calc(100% - 1px))`,
          backgroundSize: ` ${gridGap}px ${gridGap}px`,
          border: `solid ${GRID_BORDER_COLOR}`,
          boxSizing: "border-box",
        }}
      >
        <ReactGridLayout
          rowHeight={gridHeightSize}
          className="layout"
          cols={GRID_N_COLS}
          width={gridWidthSize}
          margin={[0, 0]}
          maxRows={GRID_MAX_ROWS}
          compactType={null}
          preventCollision={true}
          layout={seatLayout}
          isDraggable={isDraggable}
          onDragStop={handleDragStop}
          onDrag={handleDragSeatButton}
        >
          {seatLayout.map((layout) => {
            const currentLearner = props.learners.find(
              (learner) => learner.id == layout.i
            );
            if (currentLearner === undefined) {
              //学習者が見つからない際のエラー処理
              return <></>;
            }
            return (
              <div
                key={layout.i}
                data-grid={{
                  x: layout.x,
                  y: layout.y,
                  w: layout.w,
                  h: layout.h,
                }}
              >
                {currentSeatButton(currentLearner)}
              </div>
            );
          })}
        </ReactGridLayout>
      </Box>
    </Box>
  );
}

/**
 * LearnerからLayoutへの変換
 * @param classroomCoord
 * @returns
 */
function convertToSeatLayout(learner: Learner): Layout {
  return {
    i: learner.id,
    x: learner.coord.col,
    y: learner.coord.row,
    w: SEAT_WIDTH_RATE,
    h: SEAT_HEIGHT_RATE,
  };
}
