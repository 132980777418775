import { ExitToApp, Menu as MenuIcon } from "@mui/icons-material";
import {
  AppBar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { CustomSnackbarState } from "../components/CustomSnackbar";
import { SimpleDialog } from "../components/dialogs/SimpleDialog";
import { useAuth } from "../context/useAuth";
import { updateUser } from "../gateways/api";
import { MESSAGES } from "../message";
import { RakurakuUser } from "../types";
import { TutorialApp } from "./buttons/helps/TutorialAppHelpButton";
import { MenuButton } from "./buttons/MenuButton";

type HeaderProp = {
  rakurakuUser: RakurakuUser;
  snackbar: CustomSnackbarState;
};

export function Header(props: HeaderProp): JSX.Element {
  const [openDialogId, setOpenDialogId] = useState<string>("");
  const auth = useAuth();
  // 初回レンダー判定用
  const isFirstRender = useRef(true);

  const [commitButtonDisable, setCommitButtonDisable] = React.useState(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      setCommitButtonDisable(false);
    }
  }, [props.rakurakuUser]);

  // 変更を保存ボタンのOKクリック時の処理
  const handleClickOk = async () => {
    if (!auth.authUser) {
      props.snackbar.displayErrorMessage(MESSAGES.ERROR.AUTH.NO_AUTH);
      auth.logOut();
      return;
    } else {
      const isValid = await auth.getAuthorization(auth.authUser.email);
      if (!isValid) {
        props.snackbar.displayErrorMessage(
          MESSAGES.ERROR.SAVE.FAILURE_INVALID_USER
        );
        auth.logOut();
        return;
      }
    }
    const saveResult = await updateUser(auth.authUser.uid, props.rakurakuUser);
    setOpenDialogId("");
    if (saveResult) {
      setCommitButtonDisable(true);
      props.snackbar.displaySuccessMessage(MESSAGES.INFO.SAVE.SUCCESS);
    } else {
      props.snackbar.displayErrorMessage(MESSAGES.ERROR.SAVE.FAILURE);
    }
  };

  return (
    <AppBar position="static">
      <Toolbar disableGutters>
        <Typography variant="h6" sx={{ marginLeft: "2em" }}>
          {MESSAGES.COMMON.PRODUCT_NAME}
        </Typography>
        <div style={{ flexGrow: 1 }}></div>
        {/* コミットボタン */}
        <MenuButton
          id={"menu-button-id"}
          label={"変更を保存"}
          color={"success"}
          disabled={commitButtonDisable}
          onClick={() => setOpenDialogId("saveChanges")}
        />
        <SaveChangesDialog
          openDialogId={openDialogId}
          onClickOk={handleClickOk}
          onClickCancel={() => setOpenDialogId("")}
        />
        {/* ハンバーガー */}
        <MoreMenuList />
        <TutorialApp firstLogin={auth.firstLoginState} />
      </Toolbar>
    </AppBar>
  );
}

type SaveChangesDialogProps = {
  openDialogId: string;
  onClickOk: () => void;
  onClickCancel: () => void;
};
function SaveChangesDialog(props: SaveChangesDialogProps) {
  return (
    <SimpleDialog
      open={props.openDialogId === "saveChanges"}
      title={"変更の保存"}
      text={MESSAGES.INFO.SAVE.TRY_SAVE_CHANGES}
      onClickOk={props.onClickOk}
      onClickCancel={props.onClickCancel}
      onClose={props.onClickCancel}
    />
  );
}

function MoreMenuList() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const auth = useAuth();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickLogOut = () => {
    auth.logOut();
  };

  return (
    <>
      <Tooltip title={MESSAGES.TOOLTIPS.MENU} arrow>
        <IconButton onClick={handleMenu} sx={{ color: "WHITE" }}>
          <MenuIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClickLogOut}>
          <ListItemIcon>
            <ExitToApp color="primary" />
          </ListItemIcon>
          {"ログアウト"}
        </MenuItem>
      </Menu>
    </>
  );
}
