import { Box, Button, Card } from "@mui/material";
import React from "react";

import { CountEvaluationItemRange } from "../../types";
import { BaseSeatName } from "./styled/BaseSeat";
import { BaseSeatLabelStyled } from "./styled/customButtonStyled";

type CountButtonProps = {
  name: string;
  value: number;
  range: CountEvaluationItemRange;
  isDragging: boolean;
  onClick: (countValue: number) => void;
};
const buttonColor = {
  minColor: "#FF0000",
  maxColor: "#32CD32",
  middleColor: "#FFA500",
};

export function CountSeatButton(props: CountButtonProps): JSX.Element {
  const handlePlusClick = () => {
    if (!props.isDragging && props.value < props.range.max) {
      props.onClick(props.value + 1);
    }
  };
  const handleMinusClick = () => {
    if (!props.isDragging && props.value > props.range.min) {
      props.onClick(props.value - 1);
    }
  };

  //カウンタの値表示における色決定
  const selectLabelColor = () => {
    if (props.value >= props.range.max) {
      return buttonColor.maxColor;
    } else if (props.value <= props.range.min) {
      return buttonColor.minColor;
    } else {
      return buttonColor.middleColor;
    }
  };
  const displayLabelColor = selectLabelColor();

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <Card
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "#1976d2",
          order: 2,
        }}
      >
        <BaseSeatName name={props.name} />
        <BaseSeatLabelStyled
          sx={{
            backgroundColor: displayLabelColor,
          }}
        >
          <Box
            className="seat-button-text"
            sx={{ width: "90%", height: "100%" }}
          >
            {"－"}
          </Box>
          <Box
            className="seat-button-text"
            sx={{ width: "90%", height: "100%" }}
          >
            {props.value}
          </Box>
          <Box
            className="seat-button-text"
            sx={{ width: "90%", height: "100%" }}
          >
            {"＋"}
          </Box>
        </BaseSeatLabelStyled>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "100%",
          }}
        >
          <Button
            sx={{
              width: "50%",
              height: "100%",
              minWidth: 20,
              backgroundColor: "transparent",
            }}
            onClick={handleMinusClick}
          ></Button>
          <Button
            sx={{
              width: "50%",
              height: "100%",
              minWidth: 20,
              backgroundColor: "transparent",
            }}
            onClick={handlePlusClick}
          ></Button>
        </Box>
      </Card>
    </Box>
  );
}
